<template>
    <v-dialog v-model="isActive" width="500" @click:outside="cancelHandle">
        <div class="meeting">
            <div class="meeting__head">
                <h3 class="meeting__title">
                    {{ textContent['meeting-edit'] }}
                </h3>
            </div>
            <div class="meeting__body">
                <div class="meeting__input-wrapper">
                    <v-text-field
                        v-model="fields['date']"
                        dense
                        outlined
                        type="date"
                        name="date"
                        :label="textContent['date']"
                        :error-messages="errors['date']"
                        @input="errors['date'] = ''"
                    ></v-text-field>
                </div>
                <div class="meeting__input-wrapper">
                    <v-autocomplete
                        v-model="fields['time']"
                        outlined
                        :items="time"
                        :label="textContent['time']"
                        dense
                        :error-messages="errors['time']"
                        @input="errors['time'] = ''"
                    >
                    </v-autocomplete>
                </div>
                <div v-if="isnUsaManager" class="meeting__input-wrapper">
                    <v-autocomplete
                        v-model="fields['calendar']"
                        outlined
                        :items="types"
                        :label="textContent['calendar']"
                        dense
                        :error-messages="errors['calendar']"
                        @input="errors['calendar'] = ''"
                    >
                    </v-autocomplete>
                </div>
                <div class="meeting__input-wrapper">
                    <v-text-field
                        v-model="fields['subject']"
                        dense
                        outlined
                        type="text"
                        name="subject"
                        :label="textContent['subject']"
                        :error-messages="errors['subject']"
                        @input="errors['subject'] = ''"
                    ></v-text-field>
                </div>
            </div>
            <div class="meeting__foot">
                <div class="meeting__foot">
                    <v-btn color="#55595c" dark small @click="cancelHandle">
                        {{ textContent['close'] }}
                    </v-btn>
                    <v-btn color="#967adc" dark small @click="updateHandle">
                        {{ textContent['update'] }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { getRange } from '@/helpers/rangeFromDate'
import { getDateYyyyMmDd, getTimeHhMm } from '@/helpers/getDateInFormat'
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/calendar'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import languageMixin from '@/mixins/language.mixin'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'

export default {
    name: 'CalendarModalUpdate',
    mixins: [languageMixin],
    props: {
        meeting: {
            type: Object,
            default: () => ({}),
        },
        isActiveData: {
            type: Boolean,
            default: false,
        },
        typeOfMeetings: {
            type: String,
            default: 'orders',
        },
    },
    emits: ['close-handle'],
    data() {
        return {
            oldRange: null,
            fields: {
                subject: '',
                date: '',
                time: '',
                calendar: '',
                client: '',
                client_id: '',
                wig_id: '',
                visible: true,
                isBusy: false,
            },
            errors: {},
        }
    },
    computed: {
        types: function () {
            return [
                { text: this.textContent['orders'], value: 'ORDERS' },
                {
                    text: this.textContent['pick-up-new'],
                    value: 'PICK_UP_NEW',
                },
                { text: this.textContent['repairs'], value: 'REPAIRS' },
                {
                    text: this.textContent['pick-up-repairs'],
                    value: 'PICK_UP_REPAIRS',
                },
                { text: this.textContent['us-agent'], value: 'US_AGENT' },
            ]
        },
        textContent: function () {
            return this.getTextForSelected(this.lang, 'CalendarModalAdd') || ''
        },
        isActive: {
            get: function () {
                return this.isActiveData
            },
            set: function () {
                this.cancelHandle()
            },
        },
        time: function () {
            let time = []
            let date = new Date()
            date.setHours(9)
            date.setMinutes(0)
            const halfHour = 1800000

            for (let i = 0; i < 12; i++) {
                const h = date.getHours()
                const m =
                    date.getMinutes().toString().length === 1
                        ? date.getMinutes() + '0'
                        : date.getMinutes()
                time = [...time, `${h}:${m}`]
                date = new Date(+date + halfHour)
            }
            return time
        },
        completeDate: function () {
            return `${this.fields.date} ${this.fields.time}`
        },
        isnUsaManager: function () {
            return !this.getAllowed(['usa_manager'])
        },
    },
    watch: {
        isActive: function () {
            if (!this.isActive) return
            let type = this.meeting.calendar
            console.log(type)

            switch (type.toLowerCase()) {
                case 'pick-up new wigs':
                    this.fields.calendar = 'PICK_UP_NEW'
                    break
                case 'repairs':
                    this.fields.calendar = 'REPAIRS'
                    break
                case 'pick-up repairs':
                    this.fields.calendar = 'PICK_UP_REPAIRS'
                    break
                case 'us_agent':
                    this.fields.calendar = 'US_AGENT'
                    break
                default:
                    this.fields.calendar = 'ORDERS'
            }

            this.fields.client = this.meeting.client
            this.fields.wig_id = this.meeting.wig_id
            this.fields.client_id = this.meeting.client_id
            this.fields.subject = this.meeting.subject
            this.fields.date = getDateYyyyMmDd(this.meeting.start_at)

            this.fields.time = getTimeHhMm(this.meeting.start_at)
            this.oldRange = getRange(this.meeting.start_at)
        },
    },
    methods: {
        ...mapActions('calendar', { onUpdate: actionTypes.updateOne }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapActions('orders', {
            updateCompleteDate: actionTypesOrders.updateCompleteDate,
        }),
        cancelHandle: function () {
            this.$emit('close-handle')
        },
        updateHandle: function () {
            if (this.isBusy) return
            const data = this.fields
            const id = this.meeting.id
            const oldRange = this.oldRange
            const oldType = this.typeOfMeetings
            const oldData = this.meeting

            this.isBusy = true
            this.onUpdate({ data, id, oldRange, oldType, oldData })
                .then(() => {
                    this.success('Meeting has bee updated')
                    if (data.calendar === 'PICK_UP_NEW') {
                        this.updateCompleteDate({
                            id: this.fields.wig_id,
                            completeData: this.completeDate,
                        })
                    }
                    this.isBusy = false
                    this.cancelHandle()
                })
                .catch((er) => {
                    this.isBusy = false
                    this.errors = er
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.meeting {
    background: $color-primary-light;

    &__head {
        padding: 10px;
        border-bottom: 1px solid #eceeef;
    }

    &__title {
        font-weight: 500;
    }

    &__body {
        padding: 0 10px;
    }

    &__foot {
        padding: 0 5px 5px;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}
</style>
