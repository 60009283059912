import {
    getDateWihFirstDayOfMonth,
    getDateWihLastDayOfMonth,
} from '@/helpers/getDateInFormat'
import { parseISO, subMonths, addMonths, format } from 'date-fns'

const calendarRangeHandler = (range) => {
    range = range.replace(/^([0-9]{4})(-)([0-9]{1})$/, '$1$20$3')

    const prevMonth = format(subMonths(parseISO(range), 1), 'yyyy-MM')
    const nextMonth = format(addMonths(parseISO(range), 1), 'yyyy-MM')
    let arr = [prevMonth, range, nextMonth]

    const dateFrom = getDateWihFirstDayOfMonth(arr[0])
    const dateTo = getDateWihLastDayOfMonth(arr[arr.length - 1])

    return { dateFrom, dateTo }
}

export default calendarRangeHandler
