<template>
    <div class="list">
        <h3>{{ $t('pages.calendar.oldDatesList.header') }}</h3>
        <ul class="old-dates">
            <li
                v-for="(date, index) in oldDates"
                :key="index"
                class="old-dates__item"
            >
                {{ index + 1 }}.
                <p><DateCell :value="date.replace(/\./g, '/')" /></p>
            </li>
        </ul>
    </div>
</template>

<script>
import DateCell from '@/components/common/cells/date-cell.vue'

export default {
    name: 'CalendarOldDatesList',
    components: { DateCell },
    props: {
        oldDates: {
            type: Array,
            default: () => [
                '29.05.2023 9:00',
                '29.05.2023 9:00',
                '30.05.2023 9:00',
            ],
        },
    },
    data() {
        return {
            key: 'value',
        }
    },
}
</script>

<style lang="scss">
.old-dates {
    font-size: 13px;
    padding: 0 8px 0 !important;

    &__item {
        display: flex;

        p {
            margin: 0 4px;
            direction: ltr !important;
        }
    }
}
</style>
