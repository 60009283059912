import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login.vue'
import Home from '@/views/dashboard'
import UsersList from '@/views/users-list'
import ClientsList from '@/views/clients-list'
import PageNotFound from '@/views/page-not-found'
import Orders from '@/views/orders'
import Calendar from '@/views/calendar'
import PermissionsDenied from '@/views/permissions-denied'
import Report from '@/views/report'
import DevPage from '@/views/dev-page'
import { tokenIsAvailable } from '@/helpers/tokenHandle'
import { getStorage } from '@/helpers/Local'
// import WigCard from '@/views/[wig-id]'
import WigCard from '@/views/[wig-id]-v2'
import WigPrintPage from '@/features/wig-page/components/wigs-details-print-page.vue'

Vue.use(VueRouter)

const getAllowed = (roles) => {
    const allowedRole = getStorage('access_role') || ''

    if (!allowedRole) return false

    return !!roles.filter(
        (role) =>
            role.toLowerCase() === allowedRole.toLowerCase() ||
            role.toLowerCase() === 'all'
    )[0]
}

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            layout: 'main',
        },
        component: Home,
    },
    {
        path: '/users-list',
        name: 'UsersList',
        meta: {
            layout: 'main',
        },
        component: UsersList,
    },
    {
        path: '/clients-list',
        name: 'ClientsList',
        meta: {
            layout: 'main',
        },
        component: ClientsList,
    },
    {
        path: '/orders',
        name: 'Orders',
        meta: {
            layout: 'main',
        },
        component: Orders,
    },
    {
        path: '/orders/:wigID',
        name: 'wigCard',
        meta: {
            layout: 'main',
        },
        component: WigCard,
    },
    {
        path: '/orders/print/:wigID',
        name: 'wigPrintPage',
        component: WigPrintPage,
    },
    {
        path: '/meetings-calendar',
        name: 'Calendar',
        meta: {
            layout: 'main',
        },
        component: Calendar,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/permission-denied',
        name: 'PermissionDenied',
        meta: {
            layout: 'main',
        },
        component: PermissionsDenied,
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
    },
    {
        path: '/report',
        name: 'Report',
        meta: {
            layout: 'main',
        },
        component: Report,
    },
    {
        path: '/dev-page',
        name: 'DevPage',
        meta: {
            layout: 'main',
        },
        component: DevPage,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    const token = tokenIsAvailable()

    const cases = [
        {
            case: 'unauthorized',
            condition: () => to.name !== 'Login' && !token,
        },
        {
            case: 'authorized',
            condition: () => to.name === 'Login' && token,
        },
        {
            case: 'worker',
            condition: () =>
                token &&
                getAllowed(['worker']) &&
                ['UsersList', 'Calendar', 'ClientsList'].includes(to.name),
        },
    ]

    const action = cases.reduce((ak, item) => {
        return item.condition() ? (ak = item.case) : ak
    }, '')

    switch (action) {
        case 'unauthorized':
            next({ name: 'Login' })
            return
        case 'authorized':
            next({ path: '/' })
            return
        case 'worker':
            next({ name: 'PermissionDenied' })
            return
        default:
            next()
            return
    }
})

export default router
