export default {
    archived: 'ארכיון',
    'all-states': 'כל הסטטוסים',
    draft: 'פאות בהמתנה',
    'wigs-in-production': 'פאות בייצור',
    'ready-new-wig': 'פאות חדשות מוכנות',
    'repair-wigs': 'פאות תיקונים בעבודה',
    'ready-repair-wigs': 'פאות מתיקון מוכנות',
    'cancelled-wigs': 'פאות שבוטלו',
    'wigs-in-stock': 'פאות במלאי',
    storage: 'פאות באחסון',
    'delivered-wigs': 'פאות שנלקחו',
    rework: 'עבודה חוזרת',
    'wig-building': 'בניית פאה',
    'sawing-weft': 'ייצור טרסים',
    'sawing-weft-on-cap': 'הרכבה על הרשת',
    'skin-matching': 'התאמת סקין',
    'skins-connect': 'הרכבת סקין/לייסטופ',
    'front-hand-tie': 'עבודת יד - קלאסית',
    'first-hairline': 'עבודת יד - עומק לפני טשטוש',
    'first-hairline-tracing': 'עבודת יד - עומק לפני טשטוש',
    'second-hairline': 'עבודת יד - טשטוש אחרי עומק',
    'second-hairline-(-finishes-)': 'עבודת יד - טשטוש אחרי עומק',
    'under-lace-material-sawing': 'תפירת בד מתחת ללייס',
    'under-lace-material-closing': 'סגירת בד מתחת ללייס',
    'highlights-include-washing': 'גוונים',
    'special-shampoo': 'חומר',
    washing: 'חפיפה וכיווץ',
    'cut-and-set': 'עיצוב מראש',
    'production-start': 'התחלת ייצור',
    'production-complete': 'הייצור הושלם',
    'repair-complete': 'התיקון הושלם',
    delivery: 'מסירה',
}
