const client = {
    clientUpdate: 'Client update',
    clientContext: 'Client',
    clientSelect: 'Select client modal',
}

const calendar = {
    calendarContext: 'Calendar',
    printPage: 'CalendarPrintPage',
    calendarAddForUsa: 'Add new meeting in usa mode',
}

const order = {
    orderContext: 'Wig',
    orderAdd: 'Add new wig',
    orderPropertiesUpdate: 'Update properties',
    orderGeneralUpdate: 'Update general',
    orderStatusUpdate: 'Update status',
    orderDeposit: 'Payment info',
    orderStorage: 'Storage info',
    orderAssignCreator: 'Assign creator',
    orderClientUpdate: 'Change client',
    orderAdditionalPropertiesUpdate: 'Modal for updating additional properties',
    orderUsaTools: "Modal fro specific usa filter's tools",
    orderPaymentsNotes: 'Modal for add and update payments notes',
}

const common = {
    commonModal: 'Common container for display any other component',
}

const report = {
    reportMain: 'Report',
}

const users = {
    userProfileEdit: 'Edit user profile',
}

const extra = {
    requestTester: 'Window for testing some requests',
}

const qrCode = {
    qrCodeScan: 'Modal for scanning qr-code',
}

const wigPage = {
    repairCustomStagesUpdate: 'Slide-out to update repair custom stages',
    repairCustomStagesEdit: 'Slide-out to edit repair custom stages',
    reworkCustomStagesUpdate: 'Slide-out to update rework custom stages',
    reworkCustomStagesEdit: 'Slide-out to edit rework custom stages',
    newWigStagesUpdate: 'Slide-out to update stages',
    newWigPropertiesEdit: 'Slide-out to edit properties',
}

const popUpTypes = {
    ...common,
    ...client,
    ...calendar,
    ...order,
    ...report,
    ...users,
    ...extra,
    ...qrCode,
    ...wigPage,
}

export default popUpTypes
