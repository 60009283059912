<template>
    <div class="properties">
        <div v-if="actionsBtnIsActive" class="properties__action">
            <v-menu offset-y>
                <template #activator="{ on, attrs }">
                    <v-btn
                        :x-small="!isMobile"
                        :small="isMobile"
                        color="primary"
                        dark
                        data-test-id="wig-page--workflow-actions-btn"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ $t('pages.wigPage.workflowActionsMenu.header') }}
                    </v-btn>
                </template>
                <ul class="properties__actions-menu">
                    <li
                        v-if="!getAllowed(['usa_manager'])"
                        class="properties__actions-menu-item"
                        data-test-id="wig-page--workflow-actions-menu--updateBtn"
                        @click="() => (slideOutIsActive = true)"
                    >
                        {{ $t('pages.wigPage.workflowActionsMenu.update') }}
                    </li>
                    <li
                        v-if="
                            getAllowed(['admin', 'manager']) ||
                            (getAllowed(['usa_manager']) &&
                                order.state === 'Draft')
                        "
                        class="properties__actions-menu-item"
                        data-test-id="wig-page--workflow-actions-menu--editBtn"
                        @click="() => (editPropertiesSlideOutIsActive = true)"
                    >
                        {{
                            $t(
                                'pages.wigPage.workflowActionsMenu.editNewWigProperties'
                            )
                        }}
                    </li>
                </ul>
            </v-menu>
        </div>
        <template v-if="Object.keys(propertiesForRender).length > 0">
            <ul
                v-for="(item, key, i) in propertiesForRender"
                :key="i"
                class="properties__list"
            >
                <h4 class="properties__title">{{ textTitle(key) }}</h4>

                <li
                    v-for="(
                        { title, value, done, isAdditional }, subKey, j
                    ) in item"
                    :key="j"
                    class="properties__item"
                    :class="{ done: done, regular: !isAdditional }"
                >
                    <template v-if="typeof value === 'object'">
                        <span class="properties__sub-title">
                            {{ text(value[0]) }}</span
                        >
                        <span class="properties__value">{{
                            text(value[1])
                        }}</span>
                    </template>

                    <template v-else>
                        <div v-if="isAdditional" class="properties__item-icon">
                            <v-icon :color="done ? '#4caf50' : '#606060'" small
                                >mdi-check-bold</v-icon
                            >
                        </div>
                        <span v-if="title" class="properties__sub-title">
                            {{ text(title) }}
                        </span>
                        <span class="properties__value">{{ text(value) }}</span>
                    </template>
                </li>
            </ul>
        </template>
        <template v-else>
            <NoRecordsYet />
        </template>
        <Transition name="slide-fade" appear mode="out-in">
            <template v-if="slideOutIsActive">
                <SlideOut @on-close="() => (slideOutIsActive = false)">
                    <template #default="{ onClose }">
                        <OrderStatusUpdate @close-handle="onClose" />
                    </template>
                </SlideOut>
            </template>
            <template v-if="editPropertiesSlideOutIsActive">
                <SlideOut
                    @on-close="() => (editPropertiesSlideOutIsActive = false)"
                >
                    <template #default="{ onClose }">
                        <OrderPropertiesUpdate @close-handle="onClose" />
                    </template>
                </SlideOut>
            </template>
        </Transition>
    </div>
</template>

<script>
import isJson from '@/helpers/isJson'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import languagePropertiesMixin from '@/mixins/language-properties.mixin'
import prepareAdditionalPropertiesToRegular from '@/helpers/prepareAdditionalPropertiesToRegular'
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/order-repairs-stages'
import { getterTypes as getterTypesOrders } from '@/store/modules/orders'
import SlideOut from '@/components/common/slide-out.vue'
import OrderStatusUpdate from '@/components/orders/order-status-update.vue'
import OrderPropertiesUpdate from '@/components/orders/order-properties-update.vue'
import NoRecordsYet from '@/components/common/no-records-yes.vue'

export default {
    name: 'OrdersPropertiesList',
    components: {
        NoRecordsYet,
        OrderPropertiesUpdate,
        OrderStatusUpdate,
        SlideOut,
    },
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => {},
        },
        reworkProperties: {
            type: Array,
            default: () => [],
        },
        isRepair: { type: Boolean, default: false },
        isRework: { type: Boolean, default: false },
    },
    data() {
        return {
            slideOutIsActive: false,
            editPropertiesSlideOutIsActive: false,
            properties: {
                ['1. Wig Type']: {
                    wigType: { title: '', value: '' },
                },
                ['2. Cap Size']: {
                    capSize: { title: '', value: '' },
                    capAdjustment: { title: 'Cap adjustment', value: '' },
                },
                ['3. Top Type']: {
                    topType: { title: '', value: '' },
                    topTypeComments: { title: 'Top Type Comments', value: '' },
                },
                ['4. Front']: {
                    front: { title: '', value: '' },
                    frontComments: { title: 'Front Comments', value: '' },
                },
                ['5. Base Color']: {
                    baseColor: { title: '', value: '' },
                    skinColor: { title: 'Skin Color', value: '' },
                    highlightsColoringSelect: {
                        title: 'Highlights Coloring Select',
                        value: '',
                    },
                    highlightsColoring: {
                        title: 'Highlights Coloring',
                        value: '',
                    },
                    typeOfHighlights: {
                        title: 'Type Of Highlights',
                        value: '',
                    },
                    skinTopHighlights: {
                        title: 'Skin Top Highlights',
                        value: '',
                    },
                    frontColor: {
                        title: 'Front Color',
                        value: '',
                    },
                    withHighlights: {
                        title: 'With Highlights',
                        value: '',
                    },
                    highlightsColor: {
                        title: 'Highlights Color',
                        value: '',
                    },
                    highlightsColorSelect: {
                        title: 'Highlights Color Select',
                        value: '',
                    },
                    colorComments: {
                        title: 'Color Comments',
                        value: '',
                    },
                },
                ['6. Length']: {
                    length: { title: '', value: '' },
                    lengthFromNape: { title: 'Length From Nape', value: '' },
                    lengthFromTop: { title: 'Length From Top', value: '' },
                    sideLength: { title: 'Side Length', value: '' },
                    lengthComments: { title: 'Length Comments', value: '' },
                },
                ['7. Hair Texture On Wefts']: {
                    hairTextureOnWefts: { title: '', value: '' },
                    hairTextureComments: {
                        title: 'Hair Texture Comments',
                        value: '',
                    },
                },
                ['8. Wig Fullness']: {
                    wigFullness: { title: '', value: '' },
                    wigFullnessAdditionalAmount: {
                        title: 'Wig Fullness Additional Amount',
                        value: '',
                    },
                    wigFullnessComments: {
                        title: 'Wig Fullness Comments',
                        value: '',
                    },
                },
                ['9. Weight']: {
                    weight: { title: '', value: '' },
                },
            },
        }
    },
    computed: {
        ...mapGetters('orderRepairsStages', {
            additionalProperties: getterTypes.getAdditionalProperties,
        }),
        ...mapGetters('orders', { order: getterTypesOrders.getOrder }),
        actionsBtnIsActive: function () {
            if (this.getAllowed(['usa_manager'])) {
                return this.order.state === 'Draft'
            }
            return true
        },
        propertiesFor() {
            const res = {}
            const data = this.properties
            for (let key in data) {
                Object.keys(this.properties[key]).forEach((subKey) => {
                    const value = this.dataProperties[subKey]
                    const title = this.properties[key][subKey].title
                    if (value) {
                        res[key] = {
                            ...res[key],
                            [subKey]: {
                                title,
                                value: isJson(value)
                                    ? JSON.parse(value)
                                    : value,
                            },
                        }
                    }
                })
            }
            return res
        },
        propertiesForRender: function () {
            return this.isRework
                ? prepareAdditionalPropertiesToRegular(this.reworkProperties)
                : this.isRepair
                ? prepareAdditionalPropertiesToRegular(
                      this.additionalProperties
                  )
                : this.propertiesFor
        },
    },
    methods: {
        textTitle: function (text) {
            const num = text.match(/^[0-9]{1}\.\s/)?.[0] || ''
            const property = text.replace(/^[0-9]{1}\.\s/, '')
            return `${num} ${
                this.propertiesContent[regularToSnakeCase(property)] || property
            }`
        },
        text: function (text) {
            const translated = this.propertiesContent[regularToSnakeCase(text)]
            return translated || text
        },
    },
}
</script>

<style lang="scss" scoped>
.properties {
    padding: 5px 0;
    position: relative;

    &__list {
        padding: 3px 0;
    }

    &__title {
        background: #967adc;
        padding: 0 1rem;
        width: fit-content;
        border-radius: 0.21rem;
        color: $color-primary-light;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 3px;
    }

    &__item {
        position: relative;
        padding-bottom: 3px;
        font-size: 13px;
        display: flex;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            position: absolute;
            top: 3px;
            left: 0;
            background: transparent;
            border-radius: 50%;

            @include for-hebrew {
                left: auto;
                right: 0;
            }
        }

        &.regular {
            padding-left: 20px;

            @include for-hebrew {
                padding-left: 0;
                padding-right: 20px;
            }

            &:before {
                border: 2px solid #fff;
                background: $color-primary-dark;
            }
        }
    }

    &__item-icon {
        margin-right: 5px;
        opacity: 0.3;

        @include for-hebrew {
            margin-right: 0;
            margin-left: 5px;
        }

        .done & {
            opacity: unset;
        }
    }

    &__sub-title {
        margin-right: 7px;
        font-weight: 700;

        @include for-hebrew {
            margin-right: 0;
            margin-left: 7px;
        }

        &:after {
            content: ':';
        }
    }

    &__value {
    }

    &__action {
        position: absolute;
        top: 13px;
        right: 16px;
        left: auto;
        z-index: 100;

        @include for-hebrew {
            right: auto;
            left: 16px;
        }
    }

    &__actions-menu {
        background: #fff;
        padding: 0 !important;
    }

    &__actions-menu-item {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
            background: #967adc;
            color: #fff;
        }

        & + & {
            border-top: 1px dotted #c7c7c7;
        }
    }
}
</style>
