import wigStates from './wig-states'
import clientMeetingList from './clientMeetingList'
import noRecordsYet from './no-records-yet'
import wigTypes from './wig-types'
import warningMessages from './warning-messages'

export default {
    wigStates,
    clientMeetingList,
    noRecordsYet,
    warningMessages,
    wigTypes,
}
