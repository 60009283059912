import { render, staticRenderFns } from "./[wig-id]-v2.vue?vue&type=template&id=2833641c"
import script from "./[wig-id]-v2.vue?vue&type=script&lang=js"
export * from "./[wig-id]-v2.vue?vue&type=script&lang=js"
import style0 from "./[wig-id]-v2.vue?vue&type=style&index=0&id=2833641c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports