<template>
    <div class="status-update">
        <div class="status-update__head head">
            <h3 class="head__title">{{ textContent['status'] }}</h3>
            <v-btn icon color="#55595c" @click="closeHandle">
                <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
        </div>
        <div v-if="order.is_repair === 1" class="status-update__body body">
            <OrdersStatusRepairStages
                :id="order.id"
                @close-handle="closeHandle"
            />
        </div>
        <div v-else class="status-update__body body">
            <ul class="body__status-list status-list">
                <li
                    v-for="({ text, value }, i) in items"
                    :key="i"
                    class="status-list__item"
                >
                    <v-checkbox
                        v-model="selectedItems"
                        class="status-list__checkbox"
                        :label="textTitle(text)"
                        :value="value"
                        :readonly="!isProduction || isWorkerPreventMode(value)"
                        hide-details
                        dense
                    >
                    </v-checkbox>
                    <template v-if="selectedData && selectedData[value]">
                        <div class="status-list__user">
                            {{ selectedData[value].user }}
                        </div>
                        <div class="status-list__date">
                            <DateCell :value="selectedData[value].updated_at" />
                        </div>
                    </template>
                </li>
            </ul>
        </div>
        <div
            v-if="order.is_repair !== 1 && isProduction"
            class="status-update__footer footer"
        >
            <div class="footer__actions">
                <v-btn color="#967adc" dark small @click="updateHandle">
                    {{ textContent['update'] }}
                </v-btn>
                <v-btn color="#55595c" dark small @click="closeHandle">
                    {{ textContent['close'] }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { actionTypes } from '@/store/modules/status'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import { getterTypes } from '@/store/modules/orders'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import popUpTypes from '@/types/pop-up-types'
const { orderStatusUpdate } = popUpTypes
import parseRepairStatuses from '@/helpers/parseRepairStatuses'
import languageMixin from '@/mixins/language.mixin'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import languagePropertiesMixin from '@/mixins/language-properties.mixin'
import OrdersStatusRepairStages from '@/components/orders/orders-status-repair-stages'
import { mutationTypes } from '@/store/modules/modals'
import DateCell from '@/components/common/cells/date-cell.vue'

export default {
    name: 'OrderStatusUpdate',
    components: { DateCell, OrdersStatusRepairStages },
    mixins: [languageMixin, languageOrderExtraMixin, languagePropertiesMixin],
    emits: ['close-handle', 'closeHandle'],
    data() {
        return {
            selfName: orderStatusUpdate,
            itemsForMain: [
                { text: 'Wig Building', value: 'WIG_BUILDING' },
                { text: 'Sawing Weft', value: 'SAWING_WEFT' },
                { text: 'Skins Connect', value: 'SKINS_CONNECT' },
                { text: 'Sawing Weft On Cap', value: 'SAWING_WEFT_ON_CAP' },
                { text: 'Front Hand Tie', value: 'FRONT_HAND_TIE' },
                { text: 'First Hairline', value: 'FIRST_HAIRLINE' },
                { text: 'Second Hairline', value: 'SECOND_HAIRLINE' },
                {
                    text: 'Under Lace Material Sawing',
                    value: 'UNDER_LACE_MATERIAL_SAWING',
                },
                {
                    text: 'Under lace material closing',
                    value: 'UNDER_LACE_MATERIAL_CLOSING',
                },
                {
                    text: 'Highlights Include Washing',
                    value: 'HIGHLIGHTS_INCLUDE_WASHING',
                },
                {
                    text: 'Special Shampoo',
                    value: 'SPECIAL_SHAMPOO',
                },
                { text: 'Washing', value: 'WASHING' },
                { text: 'Cut And Set', value: 'CUT_AND_SET' },
            ],
            selectedItems: [],
            activeIndex: 0,
        }
    },
    computed: {
        ...mapGetters('orders', { order: getterTypes.getOrder }),
        selectedId: function () {
            return this.order.id
        },
        selectedData: function () {
            return this.order['status_array']
        },
        itemsForRepair: function () {
            return Object.keys(this.order.properties)
                .map((key) => parseRepairStatuses(key))
                .filter((i) => i)
        },
        items: function () {
            return this.order.is_repair
                ? this.itemsForRepair
                : this.itemsForMain
        },
        forRequest: function () {
            const status = {}
            this.selectedItems.forEach((item) => {
                status[item] = 'on'
            })

            return {
                status,
                id: this.selectedId,
            }
        },
        isProduction: function () {
            return (
                this.order.state &&
                (this.order.state.toLowerCase() === 'wigs in production' ||
                    this.order.state.toLowerCase() === 'rework')
            )
        },
    },
    mounted() {
        if (!this.selectedData) return []
        this.selectedItems = Object.keys(this.selectedData)
    },
    methods: {
        ...mapActions('status', { onUpdate: actionTypes.updateStatus }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapActions('orders', {
            addWeight: actionTypesOrders.updateProperties,
            loadOrder: actionTypesOrders.loadOrder,
        }),
        ...mapActions('confirmer', {
            promptHandle: actionTypesConfirmer.promptHandle,
        }),
        ...mapMutations('modals', {
            startLoading: mutationTypes.startLoading,
            endLoading: mutationTypes.endLoading,
        }),
        textTitle: function (text) {
            return (
                this.extraContent[regularToSnakeCase(text)] ||
                this.propertiesContent[regularToSnakeCase(text)]
            )
        },
        updateHandle: async function () {
            const alreadyStatus =
                this.order?.status_array &&
                !Object.keys(this.order?.status_array).includes(
                    'WIG_BUILDING'
                ) &&
                this.selectedItems.includes('WIG_BUILDING')
            if (alreadyStatus) {
                try {
                    const weight = await this.promptHandle(
                        this.textContent['message-add-weight']
                    )
                    if (!weight) return null
                    await this.addWeight({
                        id: this.selectedId,
                        properties: { weight },
                    })
                } catch (e) {
                    return null
                }
            }
            this.startLoading()
            this.onUpdate(this.forRequest)
                .then(async (data) => {
                    this.success('Status has been updated')
                    this.closeHandle()
                    await this.loadOrder({
                        id: this.order.id,
                        withoutOpening: true,
                    })
                    return data
                })
                .catch((er) => {
                    console.log(er)
                })
                .finally(() => {
                    setTimeout(() => {
                        this.endLoading()
                    }, 500)
                })
        },
        isWorkerPreventMode: function (value) {
            const userHasAdminRole = this.getAllowed(['admin'])
            return this.selectedItems.includes(value) && !userHasAdminRole
        },
        closeHandle: function () {
            this.$emit('close-handle')
        },
        checkMethod: function (entry) {
            console.log(entry)
            return entry
        },
    },
}
</script>

<style scoped lang="scss">
.status-update {
    background: $color-primary-light;
}

.head {
    padding: 15px 10px 5px;
    border-bottom: 1px solid #eceeef;
    @include main-head;

    &__title {
    }
}

.body {
    padding: 10px;
}

.status-list {
    padding: 0 15px;
    overflow: hidden;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 0;
        flex-wrap: wrap;

        &:last-child {
            border: none;
        }
    }

    &__checkbox {
        min-width: 320px;
        padding-right: 15px;
        margin-right: 15px;
    }

    &__user,
    &__date {
        margin-top: 4px;
        font-size: 14px;
    }

    &__user {
        margin-right: 10px;
        opacity: 0.95;
    }

    &__date {
        opacity: 0.75;
    }
}

.footer {
    padding: 10px;

    &__actions {
        display: flex;
        justify-content: space-between;
    }
}
</style>
