const ordersSearchSecondaryLoadingStates = [
    'Delivered Wigs',
    'Archived',
    'Cancelled Wigs',
    'Wigs in Stock',
]

export const getOrdersSearchSecondaryLoadingStates = (currentState) => {
    return (
        ordersSearchSecondaryLoadingStates.find((state) => {
            return state === currentState
        }) || undefined
    )
}
