import { getStorage, removeStorage, setStorage } from '@/helpers/Local'
import api from '@/api/auth'
import { initTokenExpire } from '@/helpers/tokenHandle'
import { getUserEnvMetric } from '@/helpers/getUserEnvMetric'

const state = () => ({
    accessToken: getStorage('access_token') || '',
    isDebugging: getStorage('debugging') || false,
    user: {},
    isSubmitting: false,
    notifications: [],
})

export const gettersTypes = {
    getName: '[auth] Get user name',
    getId: '[auth] Get user id',
    getAllowed: '[auth] Check permission',
    getNotification: '[auth] Get all notification',
    getDebugging: '[auth] Get debugging mode',
}

export const mutationTypes = {
    startSubmitting: '[auth] Start submitting',
    stopSubmitting: '[auth] Stop submitting',
    addToken: '[auth] Add token',
    removeToken: '[auth] Remove token',
    changeCurrentUser: '[auth] Change current user',
    putNotifications: '[auth] Put loaded notifications',
    readNotifications: '[auth] Read selected notification',
}

export const actionTypes = {
    login: '[auth] login',
    logout: '[auth] logout',
    getCurrentUser: '[auth] Get current user',
    getAllNotifications: '[auth] Get all notifications',
    readNotification: '[auth] Read selected notification',
}

const getters = {
    getStatus: ({ accessToken }) => !!accessToken,
    [gettersTypes.getName]: ({ user }) => user.nickName,
    [gettersTypes.getId]: ({ user }) => user.id,
    isSubmitting: (state) => state.isSubmitting,
    [gettersTypes.getAllowed]: (state) => (roles) => {
        const role = state?.user?.role?.name
        if (!role) return false

        return !!roles.filter(
            (item) =>
                item.toLowerCase() === role.toLowerCase() ||
                item.toLowerCase() === 'all'
        )[0]
    },
    [gettersTypes.getNotification]: ({ notifications }) => {
        const types = [
            'App\\Notifications\\WigProcessedInComplete',
            'App\\Notifications\\WigProcessedInProduction',
            'App\\Notifications\\SevenDaysBeforeMeeting',
        ]

        return notifications.filter(
            (notification) =>
                !notification.read && types.includes(notification.type)
        )
    },
    [gettersTypes.getDebugging]: ({ isDebugging }) => isDebugging,
}

const mutations = {
    [mutationTypes.changeCurrentUser](state, credentials) {
        state.user = credentials
    },
    [mutationTypes.startSubmitting](state) {
        state.isSubmiting = true
    },
    [mutationTypes.stopSubmitting](state) {
        state.isSubmiting = false
    },
    [mutationTypes.addToken](state, token) {
        state.accessToken = token
    },
    [mutationTypes.removeToken](state) {
        state.accessToken = ''
    },
    [mutationTypes.putNotifications](state, notifications) {
        state.notifications = notifications.map((ntf) => {
            return {
                ...ntf.data,
                read: !!ntf['read_at'],
                type: ntf.type,
                date: ntf['created_at'],
            }
        })
    },
    [mutationTypes.readNotifications](state, id) {
        state.notifications = state.notifications.map((ntf) =>
            ntf.id === id ? { ...ntf, read: true } : ntf
        )
    },
}

const actions = {
    [actionTypes.login]({ commit }, credentials) {
        return new Promise((res, rej) => {
            this.dialog = true
            commit(mutationTypes.startSubmitting)
            api.user
                .login(credentials)
                .then((data) => {
                    const accessToken = data['access_token']
                    const expireIn = data['expires_in']
                    removeStorage('access_token')
                    initTokenExpire(expireIn)

                    setStorage('access_token', accessToken)

                    commit(mutationTypes.addToken, accessToken)
                    commit(mutationTypes.stopSubmitting)
                    res(data)
                })
                .catch(() => {
                    rej()
                })
        })
    },
    [actionTypes.logout]({ commit }) {
        return new Promise((res) => {
            removeStorage('access_token')
            removeStorage('expire_time')
            removeStorage('language')
            removeStorage('access_role')
            commit(mutationTypes.removeToken)
            res()
        })
    },
    [actionTypes.getCurrentUser]({ commit, dispatch }) {
        return new Promise((res, rej) => {
            api.user
                .getCurrentUser()
                .then(({ data }) => {
                    // getUserEnvMetric(data.login)
                    removeStorage('access_role')
                    setStorage('access_role', data?.role?.name)
                    dispatch(actionTypes.getAllNotifications)
                    commit(mutationTypes.changeCurrentUser, data)
                    res(data)
                })
                .catch(() => {
                    dispatch(actionTypes.logout)
                    rej()
                })
        })
    },
    [actionTypes.getAllNotifications]({ commit }) {
        return new Promise((res, rej) => {
            api.user
                .getAllNotifications()
                .then(({ data }) => {
                    commit(mutationTypes.putNotifications, data.data)
                    res(data)
                })
                .catch((er) => {
                    console.log(er)
                    rej()
                })
        })
    },
    [actionTypes.readNotification]({ commit }, id) {
        api.user.readNotification(id).then(() => {
            commit(mutationTypes.readNotifications, id)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
