<template>
    <div class="orders__table-wrapper" @resize="scrollWidthHandle">
        <div
            id="extra"
            ref="extraScroll"
            class="orders__table-width-limiter"
            @scroll="onScroll"
        >
            <div
                :style="{ width: `${width}px` }"
                class="orders__extra-scroll"
            ></div>
        </div>
        <div
            id="main"
            ref="mainScroll"
            class="orders__table-width-limiter"
            @scroll="onScroll"
        >
            <div ref="table" class="orders__table">
                <v-data-table
                    :loading="isLoading"
                    calculate-widths
                    :headers="headers"
                    :page="page"
                    :items="ordersFinally"
                    :items-per-page-options="counts"
                    :items-per-page="countToShow"
                    :item-class="itemRowBackground"
                    :multi-sort="true"
                    :mobile-breakpoint="480"
                    hide-default-footer
                    data-test-id="wigs--main--table"
                    @page-count="pageCount = $event"
                    @update:page="() => {}"
                    @click:row="openWigDetailsPage"
                >
                    <!--    eslint-disable-next-line -->
                    <template #item.repair="{ item }">
                        <button class="icon-container">
                            <v-icon v-if="item.repair" small color="#da4453">
                                mdi-wrench
                            </v-icon>
                        </button>
                    </template>
                    <!--    eslint-disable-next-line -->
                    <template #item.client="{ item }">
                        <p class="client client--name">{{ item.clientName }}</p>
                        <p class="client client--phone">
                            {{ phone(item.clientPhone) }}
                        </p>
                        <p
                            v-if="item.user_generated_id"
                            class="client client--uniq"
                        >
                            {{ item.user_generated_id }}
                        </p>
                    </template>
                    <!--    eslint-disable-next-line -->
                    <template #item.status="{ item }">
                        <div class="orders__status">
                            {{ item.status }}
                        </div>
                    </template>
                    <!--    eslint-disable-next-line -->
                    <template #item.state="{ item }">
                        {{ translate(item.state) }}
                    </template>
                    <!--    eslint-disable-next-line -->
                    <template #item.delivery="{ item }">
                        <DateCell
                            :value="item.delivery"
                            format-type="dd-MM-yyyy"
                        />
                    </template>
                    <!--    eslint-disable-next-line -->
                    <template #header.baseColor="{ header }">
                        <div class="orders__header orders__header--base-color">
                            {{ header.text }}
                        </div>
                    </template>
                    <!--    eslint-disable-next-line -->
                    <template #header.lengthFromNape="{ header }">
                        <div
                            class="orders__header orders__header--length-from-ape"
                        >
                            {{ header.text }}
                        </div>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-pagination
            v-if="orders.length !== 0"
            v-model="page"
            :length="pageCount"
            :total-visible="7"
            color="#3bafda"
        ></v-pagination>
    </div>
</template>

<script>
import { getFullDayFirst, getInMilliseconds } from '@/helpers/getDateInFormat'
import { sortBy } from 'lodash'
import clientNameTrim from '@/helpers/clientNameTrimer'
import phoneNumberView from '@/helpers/phone-number-view'
import languageMixin from '@/mixins/language.mixin'
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/orders'
import { gettersTypes as getterTypesAuth } from '@/store/modules/auth'
import DateCell from '@/components/common/cells/date-cell.vue'
import getWigStatusByState from '@/helpers/getWigStatusByState'

export default {
    name: 'OrdersTable',
    components: { DateCell },
    mixins: [languageMixin],
    props: {
        orders: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['select-order-handle'],
    data() {
        return {
            width: 10,
            page: 1,
            pageCount: 0,
            counts: 25,
            countToShow: 25,
        }
    },
    computed: {
        ...mapGetters('orders', { isLoading: getterTypes.isLoading }),
        ...mapGetters('auth', { isDebugging: getterTypesAuth.getDebugging }),
        extraContent: function () {
            return this.getTextForSelected(this.lang, 'orderExtra') || ''
        },
        headers: function () {
            let headers = [
                {
                    text: this.textContent['barcode'],
                    value: 'barcode',
                    sortable: false,
                },
                {
                    text: this.textContent['state'],
                    value: 'state',
                    sortable: false,
                    width: 130,
                },
                {
                    text: this.textContent['client'],
                    value: 'client',
                    sortable: false,
                },
                this.getAllowed(['admin', 'manager'])
                    ? {
                          text: this.textContent['status'],
                          value: 'status',
                          sortable: false,
                          hide: true,
                      }
                    : null,
                { text: ' ', value: 'repair', sortable: false },
                {
                    text: this.textContent['to-date'],
                    value: 'delivery',
                    width: 120,
                    sortable: true,
                },
                {
                    text: this.textContent['base-color'],
                    value: 'baseColor',
                    sortable: true,
                    width: 105,
                },
                {
                    text: this.textContent['length-from-nape'],
                    value: 'lengthFromNape',
                    sortable: false,
                    width: 60,
                },
                {
                    text: 'creator',
                    value: 'manager_nickname',
                    sortable: false,
                    width: 145,
                },
            ].filter((i) => i)
            return headers
        },
        ordersFinally: function () {
            const array = this.orders.map((item) => ({
                ...item,
                status: getWigStatusByState(item),
                date: getFullDayFirst(item['created_at']),
                dateForSort: getInMilliseconds(item['created_at']),
            }))
            return sortBy(array, ['dateForSort'])
                .map((item) => {
                    const { clientName, clientPhone } = clientNameTrim(
                        item.client
                    )
                    return {
                        ...item,
                        clientName,
                        clientPhone,
                        ['manager_nickname']:
                            item.is_usa_manager === 1
                                ? item?.['manager_nickname']
                                : '',
                        repair: !!item['is_repair'],
                        delivery: item['complete_data']
                            ? getInMilliseconds(item['complete_data'])
                            : '',
                    }
                })
                .reverse()
        },
    },
    updated() {
        let statuses = []
        this.orders.forEach(({ state }) => {
            if (!statuses.includes(state)) statuses = [...statuses, state]
        })
    },
    mounted() {
        this.scrollWidthHandle()
        window.addEventListener('resize', this.scrollWidthHandle)
    },
    methods: {
        phone: function (phone) {
            return phoneNumberView(phone)
        },
        scrollWidthHandle: function () {
            this.width = this.$refs.table.clientWidth
        },
        onScroll: function (e) {
            const { scrollLeft, id } = e.target
            return id === 'main'
                ? this.$refs.extraScroll.scroll(scrollLeft, 0)
                : this.$refs.mainScroll.scroll(scrollLeft, 0)
        },
        loadWigs: function () {},
        openWigDetailsPage: function (e, { item }) {
            this.$router.push({ path: `/orders/${item.id}` })
        },
        selectOrderHandle: function ({ id }) {
            this.$emit('select-order-handle', id)
        },
        translate: function (state) {
            const hasSpace = /\s/.test(state)
            state = state.replace(/,/g, '')
            return !state
                ? ''
                : hasSpace
                ? this.extraContent[state.replace(/\s/g, '-').toLowerCase()]
                : this.extraContent[state.toLowerCase()]
                ? this.extraContent[state.toLowerCase()]
                : `*${state}`
        },
        date: function (date) {
            return date ? getFullDayFirst(date) : ''
        },
        itemRowBackground: function (item) {
            let classBackground = ''
            const condition = item.state?.toLowerCase()

            switch (condition) {
                //     New wigs prod - orange like today.
                case 'wigs in production':
                    classBackground = 'orders__row--orange'
                    break
                case 'repair wigs':
                    classBackground = 'orders__row--yellow'
                    break
                case 'ready repair wigs':
                    classBackground = 'orders__row--light-blue'
                    break
                case 'ready new wig':
                    classBackground = 'orders__row--green'
                    break
                case 'cancelled wigs':
                    classBackground = 'orders__row--red'
                    break
                case 'storage':
                    classBackground = 'orders__row--blue'
                    break
                case 'delivered wigs':
                    classBackground = 'orders__row--light-brown'
                    break
                case 'wigs in stock':
                    classBackground = 'orders__row--pink'
                    break
                case 'rework':
                    classBackground = 'orders__row--dark-orange'
                    break
                case 'archived':
                    classBackground = 'orders__row--silver'
                    break
                case 'draft':
                default:
                    classBackground = 'white'
            }

            return classBackground
        },
    },
}
</script>

<style lang="scss">
.orders {
    &__table-wrapper {
    }

    &__table-width-limiter {
        max-width: 100%;
        overflow: auto;
    }

    &__table {
        @include tablet-large-up {
            padding: 10px 0;
            min-width: 1100px;
        }
    }

    &__status {
        text-align: center;
    }

    &__header {
        font-size: 0.9em;
        text-align: center;
        text-align: center;

        &--base-color {
            float: left;
            max-width: 32px;
        }
    }

    &__row {
        &--orange {
            background: #f6bb42;
        }

        &--green {
            background: #6af789;
        }

        &--yellow {
            background: #ffed01;
        }

        &--light-blue {
            background: #7decff;
        }

        &--red {
            background: #fd5959;
        }

        &--blue {
            background: #57a3ff;
        }

        &--light-brown {
            background: #b09789;
        }

        &--pink {
            background: #e971ff;
        }

        &--dark-orange {
            background: #ff7200;
        }

        &--silver {
            background: #c5c5c5;
        }
    }

    &__extra-scroll {
        width: 100%;
        height: 1px;
        margin-bottom: 10px;
    }
}

.client {
    margin-bottom: 5px !important;

    &:first-child {
        margin-top: 4px;
    }

    &--uniq {
        font-size: 0.9em;
        border: 1px solid #7f7f7f;
        width: fit-content;
        padding: 2px 5px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.5);
    }
}
</style>
