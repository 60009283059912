<template>
    <div class="reports-table">
        <div class="reports-table__actions">
            <div class="reports-table__filter">
                <v-autocomplete
                    v-model="filters.client.value"
                    :items="filtersItems.clients"
                    dense
                    label="Client"
                    hide-details
                    placeholder="Client"
                ></v-autocomplete>
            </div>
            <div class="reports-table__filter">
                <v-autocomplete
                    v-model="filters.user.value"
                    :items="filtersItems.users"
                    dense
                    label="Employee"
                    hide-details
                    placeholder="Employee"
                ></v-autocomplete>
            </div>
            <div class="reports-table__filter">
                <v-autocomplete
                    v-model="filters.source.value"
                    :items="filters.source.items"
                    dense
                    label="Source"
                    hide-details
                    placeholder="Source"
                ></v-autocomplete>
            </div>
        </div>
        <div class="reports-table__table">
            <v-data-table
                :headers="headers"
                :items="filteredPayments"
                :items-per-page="-1"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
                dense
                @click:row="clickHandle"
            >
                <!--    eslint-disable-next-line -->
                <template #body.append="{ headers }">
                    <tr class="reports-table__totally-row">
                        <td :colspan="headers.length - 1">
                            <p class="reports-table__totally">Totally:</p>
                        </td>
                        <td :colspan="1">{{ totally }}</td>
                    </tr>
                </template>
                <!--    eslint-disable-next-line -->
                <template #item.remainder="{ item }">
                    <div
                        v-if="item?.remainder"
                        class="reports-table__reminder"
                        :class="getColor(item.status)"
                    >
                        {{ item.remainder }}
                    </div>
                    <span v-else>-</span>
                </template>
                <!--    eslint-disable-next-line -->
                <template #item.created_at="{ item }">
                    <span style="direction: ltr; display: inline-block">
                        <DateCell :value="item.created_at" />
                    </span>
                </template>
                <!--    eslint-disable-next-line -->
                <template #item.status="{ item }">
                    <template v-if="item.remainder">
                        <v-progress-circular
                            :rotate="270"
                            :size="20"
                            :width="5"
                            :value="item.status"
                            :color="getColor(item.status)"
                        >
                        </v-progress-circular>
                    </template>
                    <template v-else>
                        <v-progress-circular
                            :rotate="270"
                            :size="20"
                            :width="5"
                            value="100"
                            color="#999"
                        >
                        </v-progress-circular>
                    </template>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import languageMixin from '@/mixins/language.mixin'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import { mapActions, mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/report'
import { getterTypes as getterTypesUsers } from '@/store/modules/users'
import { getterTypes as getterTypesClients } from '@/store/modules/clients'
import { getFullDayFirstFull } from '@/helpers/getDateInFormat'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import formatDate from '@/helpers/format-date'
import DateCell from '@/components/common/cells/date-cell.vue'
import wigTransitions from '@/helpers/transitions/wig-transitions'

export default {
    name: 'ReportsPaymentTable',
    components: { DateCell },
    mixins: [languageMixin, languageOrderExtraMixin],
    data() {
        return {
            key: '',
            search: '555',
            filters: {
                client: {
                    items: [
                        {
                            text: 'any',
                            value: '',
                        },
                        {
                            text: 'Client 001',
                            value: 'client-001',
                        },
                        {
                            text: 'Client 002',
                            value: 'client-002',
                        },
                    ],
                    value: '',
                },
                user: {
                    items: [
                        {
                            text: 'any',
                            value: '',
                        },
                    ],
                    value: '',
                },
                source: {
                    items: [
                        {
                            text: 'any',
                            value: '',
                        },
                        {
                            text: 'Card',
                            value: 'card',
                        },
                        {
                            text: 'Cash',
                            value: 'Cash',
                        },
                        {
                            text: 'Payout check',
                            value: 'payout check',
                        },
                        {
                            text: 'Wire transfer',
                            value: 'wire transfer',
                        },
                    ],
                    value: '',
                },
            },
        }
    },
    computed: {
        ...mapGetters('report', {
            getAllPayments: getterTypes.getAllPayments,
        }),
        ...mapGetters('users', {
            getAllUsers: getterTypesUsers.getAllUsers,
        }),
        ...mapGetters('clients', {
            getAllClients: getterTypesClients.getAllClients,
        }),
        textPayment: function () {
            return (
                this.getTextForSelected(this.lang, 'OrderModalDepositUpdate') ||
                ''
            )
        },
        totally: function () {
            return this.filteredPayments.reduce((ak, { amount }) => {
                return (ak = ak + +amount)
            }, 0)
        },
        headers: function () {
            return [
                { text: this.textContent['created'], value: 'created_at' },
                {
                    text: this.textContent['client'],
                    value: 'client',
                    sortable: false,
                },
                {
                    text: this.textContent['barcode'],
                    value: 'barcode',
                    sortable: false,
                },
                {
                    text: this.textContent['employee'],
                    value: 'employee',
                    sortable: false,
                },
                {
                    text: this.textContent['source'],
                    value: 'source',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: this.textContent['amount'],
                    value: 'amount',
                    align: 'center',
                },
                {
                    text: 'Remainder',
                    value: 'remainder',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                    sortable: false,
                },
            ]
        },
        filteredPayments: function () {
            return this.getAllPayments.filter((item) => {
                return (
                    (!this.filters.client.value
                        ? true
                        : this.filters.client.value.toLowerCase() ===
                          item?.client?.toLowerCase()) &&
                    (!this.filters.user.value
                        ? true
                        : this.filters.user.value.toLowerCase() ===
                          item?.employee?.toLowerCase()) &&
                    (!this.filters.source.value
                        ? true
                        : this.filters.source.value.toLowerCase() ===
                          item?.source?.toLowerCase())
                )
            })
        },
        filtersItems: function () {
            const clients = this.getAllClients.map(({ full_name }) => ({
                text: full_name,
                value: full_name,
            }))
            const users = this.getAllUsers.map(({ nickName }) => ({
                text: nickName,
                value: nickName,
            }))
            return {
                clients: [{ text: 'any', value: '' }, ...clients],
                users: [{ text: 'any', value: '' }, ...users],
            }
        },
    },
    methods: {
        ...mapActions('orders', {
            getOrder: actionTypesOrders.loadOrder,
        }),
        dateFormat: function (date) {
            return getFullDayFirstFull(date)
        },
        filterOnlyCapsText() {
            return true
        },
        getColor: function (value) {
            return value >= 100 ? 'green' : value >= 50 ? 'orange' : 'red'
        },
        clickHandle: function (e) {
            if (e.wig_id) {
                wigTransitions.moveToWigPageInNewTab(e.wig_id)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.reports-table {
    &__totally-row {
        font-weight: 700;
        font-size: 1.1em;
    }
    &__totally {
        width: 100%;
        text-align: right;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    &__filter {
        max-width: 200px;
    }

    &__reminder {
        border: 2px solid;
        border-radius: 15px;
        width: 65%;
        padding: 2px 10px;
        margin: 0 auto;
        background: #fff !important;
    }
}
</style>
