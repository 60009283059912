<template>
    <section class="calendar">
        <div
            v-if="
                selectedDay && typeOfCalendar && meetingsForPrint.length !== 0
            "
            id="print"
            class="calendar__print-container"
        >
            <CalendarPrintPage
                :date="selectedDay"
                :type-of-meetings="typeOfCalendar"
                :get-meeting-per-day="meetingsForPrint"
            />
        </div>
        <CalendarModalAdd
            :fields-data="requestData.forClient"
            :is-active-data="modals.add.isActive"
            @close-handle="closeAdd"
            @on-add="addHandle"
        />
        <CalendarModalUpdate
            :is-active-data="modals.update.isActive"
            :meeting="selectedMeeting"
            :type-of-meetings="typeOfCalendar"
            @close-handle="closeUpdate"
        />
        <SubHeader
            :title="textContent['title']"
            :crumbs="textContent['crumbs']"
        />
        <div class="calendar__body">
            <div class="calendar__actions">
                <template v-if="isMobile">
                    <v-select
                        v-model="typeOfCalendar"
                        color="#967adc"
                        dense
                        :items="buttons"
                        outlined
                    ></v-select>
                </template>
                <template v-else>
                    <v-btn
                        v-for="({ text, value, color }, i) in buttons"
                        :key="i"
                        :color="color"
                        small
                        dark
                        max-width="18%"
                        :outlined="value === typeOfCalendar"
                        @click="() => onSwitch(value)"
                    >
                        {{ isMobileText ? cutterForBtnText(text) : text }}
                    </v-btn>
                </template>

                <v-btn
                    v-if="!isMobile"
                    min-width="50"
                    color="#967adc"
                    small
                    dark
                    outlined
                    @click="printHandle"
                >
                    <v-icon small>fa-print</v-icon>
                </v-btn>
            </div>

            <div class="calendar__main">
                <CalendarMain
                    :start-data="start"
                    :meetings="meetings"
                    :type-of-meetings="typeOfCalendar"
                    :is-mobile="isMobile"
                    :type-of-format="typeOfFormat"
                    @load-range="onLoad"
                    @create-meeting="onCreate"
                    @update-handle="openUpdate"
                    @select-day="selectDayHandle"
                />
            </div>
        </div>
    </section>
</template>

<script>
import SubHeader from '@/components/common/sub-header'
import CalendarMain from '@/components/calendar/calendar-main'
import CalendarModalAdd from '@/components/modals/calendar-modal-add'
import CalendarModalUpdate from '@/components/modals/calendar-modal-update'
import CalendarPrintPage from '@/components/modals/calendar-print-page'

import { mapActions, mapGetters } from 'vuex'
import {
    actionTypes,
    getterTypes as getterTypesCalendar,
    getterTypes,
} from '@/store/modules/calendar'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import { getterTypes as getterTypesModals } from '@/store/modules/modals'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { actionTypes as actionTypesModals } from '@/store/modules/modals'

import popUpTypes from '@/types/pop-up-types'
import { getInMilliseconds, getRange } from '@/helpers/getDateInFormat'
import languageMixin from '@/mixins/language.mixin'

const { calendarContext } = popUpTypes

export default {
    name: 'Calendar',
    components: {
        SubHeader,
        CalendarMain,
        CalendarModalAdd,
        CalendarModalUpdate,
        CalendarPrintPage,
    },
    mixins: [languageMixin],
    data() {
        return {
            typeOfFormat: 'month',
            windowWidth: window.innerWidth,
            start: null,
            transitData: null,
            typeOfCalendar: 'orders',
            selectedDay: '',
            isBusy: false,
            modals: {
                add: {
                    isActive: false,
                },
                update: {
                    isActive: false,
                },
            },
            selectedMeeting: {},
            requestData: {
                forClient: {
                    fields: {
                        subject: '',
                        client_id: null,
                        date: '',
                        time: '',
                        calendar: '',
                        wig_id: null,
                        client: '',
                    },
                    errors: {},
                },
            },
        }
    },
    computed: {
        ...mapGetters('calendar', {
            orders: getterTypes.getAllForOrders,
            pickUp: getterTypes.getAllForPickUpNew,
            repairs: getterTypes.getAllForRepairs,
            pickUpRepairs: getterTypes.getAllForPickUpRepairs,
            usAgent: getterTypes.getAllForUsAgent,
            getMeetingPerDay: getterTypesCalendar.getMeetingsPerDay,
        }),
        ...mapGetters('modals', {
            checkIsActive: getterTypesModals.isActive,
            payload: getterTypesModals.payload,
        }),
        buttons: function () {
            const isUsaAgent = this.getAllowed(['usa_manager'])
            const types = [
                {
                    text: this.textContent['orders'],
                    value: 'orders',
                    color: '#967adc',
                },
                {
                    text: this.textContent['pick-up-new'],
                    value: 'pick_up_new',
                    color: '#37bc9b',
                },
                {
                    text: this.textContent['repairs'],
                    value: 'repairs',
                    color: '#f6bb42',
                },
                {
                    text: this.textContent['pick-up-repairs'],
                    value: 'pick_up_repairs',
                    color: '#f6bb42',
                },
                {
                    text: this.textContent['us-agent'],
                    value: 'us_agent',
                    color: '#b05454',
                },
            ]
            return isUsaAgent
                ? types.filter(({ value }) => value === 'us_agent')
                : types
        },
        isActive: function () {
            return this.checkIsActive(calendarContext)
        },
        extraData: function () {
            return this.payload(calendarContext)
        },
        isMobile: function () {
            return this.windowWidth < 375
        },
        isMobileText: function () {
            return this.windowWidth < 1024
        },
        completeDate: function () {
            return `${this.requestData.forClient.fields.date} ${this.requestData.forClient.fields.time}`
        },
        meetings: function () {
            switch (this.typeOfCalendar) {
                case 'orders':
                    return this.orders
                case 'pick_up_new':
                    return this.pickUp
                case 'repairs':
                    return this.repairs
                case 'pick_up_repairs':
                    return this.pickUpRepairs
                case 'us_agent':
                    return this.usAgent
                default:
                    return []
            }
        },
        meetingsForPrint: function () {
            return this.getMeetingPerDay(this.typeOfCalendar, this.selectedDay)
        },
    },
    watch: {
        isActive: function (value) {
            if (value) {
                this.setState()
            }
        },
        buttons: function () {
            this.typeOfCalendar = this.buttons[0].value
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
        this.setState()
        this.typeOfCalendar = this.buttons[0].value
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        ...mapActions('calendar', {
            getMeetings: actionTypes.getAllForSelected,
            onAdd: actionTypes.addNewOne,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
            error: actionTypesModalNotifications.error,
        }),
        ...mapActions('orders', {
            updateCompleteDate: actionTypesOrders.updateCompleteDate,
        }),
        ...mapActions('confirmer', {
            confrimHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('modals', { onOpen: actionTypesModals.openPopUp }),
        setState: function () {
            const action = this.extraData?.action
            if (!action) return null
            switch (action) {
                case 'for-client':
                    this.requestData.forClient.fields.client_id =
                        this.extraData?.clientId
                    break
                case 'for-wig':
                    this.requestData.forClient.fields.wig_id =
                        this.extraData.wigId
                    this.requestData.forClient.fields.client_id =
                        this.extraData.clientId
                    this.typeOfCalendar = this.extraData.type
                    break
                case 'to-date':
                    this.typeOfCalendar = this.extraData.type || 'orders'
                    this.typeOfFormat = 'day'
                    this.start = new Date(
                        getInMilliseconds(this.extraData.selectDate)
                    )
                    // eslint-disable-next-line no-case-declarations
                    const detail = {
                        type: this.typeOfCalendar,
                        range: getRange(this.start),
                    }
                    this.onLoad(detail)
                    break
                default:
                    return
            }
        },
        onLoad(detail) {
            this.getMeetings(detail)
        },
        onSwitch(type) {
            this.typeOfCalendar = type
        },
        openAdd: function () {
            this.modals.add.isActive = true
        },
        closeAdd: function () {
            this.modals.add.isActive = false
            Object.keys(this.requestData.forClient.fields).forEach(
                (key) => (this.requestData.forClient.fields[key] = '')
            )
        },
        openUpdate: function (meeting) {
            this.selectedMeeting = meeting
            this.modals.update.isActive = true
        },
        closeUpdate: function () {
            this.modals.update.isActive = false
        },
        onCreate: function ({ date, time }) {
            this.requestData.forClient.fields.date = date
            this.requestData.forClient.fields.time = time
            this.requestData.forClient.fields.calendar =
                this.typeOfCalendar.toUpperCase()
            this.openAdd()
        },
        addHandle: function () {
            if (this.isBusy) return
            let data = {}
            Object.keys(this.requestData.forClient.fields).forEach((field) => {
                if (this.requestData.forClient.fields[field])
                    data = {
                        ...data,
                        [[field]]: this.requestData.forClient.fields[field],
                    }
            })
            this.isBusy = true
            this.onAdd(data)
                .then((res) => {
                    this.success('Meeting has been added')
                    if (this.requestData.forClient.fields.wig_id) {
                        this.updateCompleteDate({
                            id: this.requestData.forClient.fields.wig_id,
                            completeData: this.completeDate,
                        })
                    }
                    this.closeAdd()
                    return res
                })
                .catch((errors) => {
                    if (!errors) {
                        this.error('something went wrong')
                        this.closeAdd()
                        return
                    }
                    this.requestData.forClient.errors = errors
                })
                .finally(() => (this.isBusy = false))
        },
        onResize() {
            this.windowWidth = window.innerWidth
        },
        printHandle: function () {
            if (
                !this.selectedDay ||
                !this.typeOfCalendar ||
                this.getMeetingPerDay(this.typeOfCalendar, this.selectedDay)
                    .length === 0
            )
                return null

            // let route = this.$router.resolve({ path: '/calendar-print' })
            // window.open(route.href)
            //
            // const payload = {
            //     type: this.typeOfCalendar,
            //     date: this.selectedDay,
            // }

            this.print()
        },
        async print() {
            // Pass the element id here
            await this.$htmlToPaper('print')
        },
        cutterForBtnText: function (text) {
            return text?.split('')?.slice(0, 11)?.join('') || ''
        },
        selectDayHandle: function (date) {
            this.selectedDay = date
        },
    },
}
</script>

<style lang="scss" scoped>
.calendar {
    &__print-container {
        display: none;
    }

    &__actions {
        @media print {
            display: none;
        }

        button + button {
            margin-left: 7px;

            @include for-hebrew {
                margin-left: 0;
                margin-right: 7px;
            }

            @include tablet-large-up {
                margin-left: 15px;
            }
        }
    }
}
</style>
