import axios from '@/api/axios'

export default {
    putNewStage: ({ wigID, properties }) =>
        axios.post(`/base/api/wig/${wigID}/additionalProperties`, {
            properties,
        }),
    doneStage: ({ wigID, ids }) =>
        axios.put(`/base/api/wig/${wigID}/additionalProperties/done`, {
            properties_id: ids,
        }),
    unDoneStage: ({ wigID, ids }) =>
        axios.put(`/base/api/wig/${wigID}/additionalProperties/undone`, {
            properties_id: ids,
        }),
    deleteStage: ({ id, property_id }) =>
        axios.delete(`/base/api/wig/${id}/additionalProperties/${property_id}`),
}
