<template>
    <ul class="wigs">
        <li v-for="{ id, text } of wigsList" :key="id" class="wigs__item">
            <button
                class="wigs__link"
                @click="
                    () => {
                        goToSelectedOrder(id)
                    }
                "
            >
                <v-icon color="" small>fas fa-external-link-square-alt</v-icon>
            </button>
            <p class="wigs__desc">{{ text }}</p>
        </li>
    </ul>
</template>

<script>
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/orders'
import wigTransitions from '@/helpers/transitions/wig-transitions'

export default {
    name: 'ClientWigsList',
    props: {
        wigs: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        wigsList: function () {
            return this.wigs.map(({ id, state, deposit, manager, info }) => ({
                id,
                text: `State: ${state}, Deposit: ${deposit}, Manager: ${manager}, Info: ${info}`,
            }))
        },
    },
    methods: {
        ...mapActions('orders', { getOrder: actionTypes.loadOrder }),
        goToSelectedOrder: function (id) {
            wigTransitions.moveToWigPageInNewTab(id)
        },
    },
}
</script>

<style lang="scss" scoped>
.wigs {
    &__item {
        align-items: center;
        display: flex;
        font-size: 13px;
        color: #55595c;
    }

    &__desc {
    }

    &__link {
        margin-right: 5px;

        i {
            color: #3bafda;
        }
    }
}
</style>
