import { render, staticRenderFns } from "./orders-rework-tab.vue?vue&type=template&id=1e11b8ae&scoped=true"
import script from "./orders-rework-tab.vue?vue&type=script&lang=js"
export * from "./orders-rework-tab.vue?vue&type=script&lang=js"
import style0 from "./orders-rework-tab.vue?vue&type=style&index=0&id=1e11b8ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e11b8ae",
  null
  
)

export default component.exports