<template>
    <div
        class="wig-page-header"
        :style="`background:${getWigColorByState(wig.state || '')}`"
    >
        <button class="wig-page-header__btn" @click="openClientCard">
            {{ wig['client'] }}
        </button>
        <v-btn
            v-if="changeClientBtnIsActive"
            dark
            color="#967adc"
            :x-small="!isMobile"
            :small="isMobile"
            @click="changeClientHandle"
        >
            <v-icon small>mdi-pencil</v-icon>
        </v-btn>
    </div>
</template>

<script>
import popUpTypes from '@/types/pop-up-types'
import getWigColorByState from '@/helpers/getWigColorByState'
import { mapActions } from 'vuex'
import { actionTypes as actionTypesModals } from '@/store/modules/modals'
import { actionTypes as actionTypesClients } from '@/store/modules/clients'
const { orderClientUpdate, clientContext } = popUpTypes

export default {
    name: 'WigPageHeader',
    props: {
        wig: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        changeClientBtnIsActive: function () {
            if (!this.getAllowed(['admin', 'manager'])) {
                return false
            }

            const state = this.order?.state?.toLowerCase() || ''
            return state !== 'archived'
        },
    },
    methods: {
        ...mapActions('modals', { onOpen: actionTypesModals.openPopUp }),
        ...mapActions('clients', { getClient: actionTypesClients.getClient }),
        getWigColorByState,
        changeClientHandle: function () {
            this.onOpen(orderClientUpdate)
        },
        openClientCard: function () {
            this.getClient(this.wig.client_id)
        },
    },
}
</script>

<style lang="scss">
.wig-page-header {
    position: relative;
    background: #fff;
    padding: 8px 40px;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    gap: 6px;
    z-index: 1;

    p,
    span {
        margin-bottom: 0;
        position: relative;
        z-index: 2;
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.05);
        top: 0;
        left: 0;
        z-index: 1;
    }

    &__head-id {
        margin-left: 10px;
        font-size: 0.9em;
        height: fit-content;
        line-height: 1;
        opacity: 0.85;
        border: 1px solid #5d5d5d;
        border-radius: 4px;
        padding: 3px 5px;
        background: rgba(206, 206, 206, 0.5);

        @include for-hebrew {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    &__btn {
        cursor: pointer;
        position: relative;
        z-index: 2;
        text-align: left;
        margin-right: 10px;

        @include for-hebrew {
            margin-right: 0;
            margin-left: 10px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
