<template>
    <div
        class="orders-page-payments"
        data-test-id="wig-page--payments--wrapper"
    >
        <div class="orders-page-payments__total">
            <p>{{ $t('pages.wigPage.ordersPagePayments.header') }}</p>
            <p data-test-id="wig-page--payments--total-price">
                {{ totalPrice }}
            </p>
        </div>

        <div class="orders-page-payments__head">
            <h3 class="orders-page-payments__title">
                {{ $t('pages.wigPage.ordersPagePayments.paymentsHistory') }}:
            </h3>
        </div>
        <div class="orders-page-payments__body">
            <div class="orders-page-payments__status">
                <v-progress-circular
                    :value="status"
                    :rotate="270"
                    color="teal"
                    :width="7"
                    :size="35"
                >
                </v-progress-circular>
                {{ status }}%
            </div>
            <template v-if="payments.length !== 0">
                <OrderDepositHistory />
            </template>
            <template v-else>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#9E9E9E"
                    height="64"
                    viewBox="0 0 24 24"
                    width="64"
                >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm2 15h-4v-4h4v4zm0-6h-4V7h4v4z"
                    />
                </svg>
                <p>{{ $t('pages.wigPage.ordersPagePayments.noDepositYet') }}</p>
            </template>
        </div>
        <div class="orders-page-payments__head">
            <h3 class="orders-page-payments__title">
                {{ $t('pages.wigPage.ordersPagePayments.paymentsNotes') }}:
            </h3>
        </div>
        <div class="orders-page-payments__body">
            <div
                class="orders-page-payments__payments-notes"
                data-test-id="wig-page--payments--notes"
            >
                {{ this.order.info }}
            </div>
        </div>
        <div
            class="orders-page-payments__total orders-page-payments__total--deposit"
        >
            <p>{{ $t('pages.wigPage.ordersPagePayments.totalDeposit') }}:</p>
            <p data-test-id="wig-page--payments--total-deposit">
                {{ totalDeposit }}
            </p>
        </div>

        <div
            v-if="!readonly && getAllowed(['admin', 'manager'])"
            class="orders-page-payments__actions"
        >
            <v-btn
                min-width="80"
                :x-small="!isMobile"
                :small="isMobile"
                color="#967adc"
                dark
                data-test-id="wig-page--payments--add-payment-btn"
                @click="addPayment"
                >{{
                    $t('pages.wigPage.ordersPagePayments.actions.addPayment')
                }}</v-btn
            >
            <v-btn
                min-width="80"
                :x-small="!isMobile"
                :small="isMobile"
                color="#3bafda"
                dark
                data-test-id="wig-page--payments--add-note-btn"
                @click="addNotes"
            >
                {{
                    $t('pages.wigPage.ordersPagePayments.actions.addNote')
                }}</v-btn
            >
        </div>
    </div>
</template>

<script>
import OrderDepositHistory from '@/components/orders/order-deposit-history.vue'
import { mapActions, mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/payments'
import { getterTypes as gettersTypesOrders } from '@/store/modules/orders'
import { actionTypes } from '@/store/modules/modals'
import popUpTypes from '@/types/pop-up-types'
const { orderDeposit, orderPaymentsNotes } = popUpTypes

export default {
    name: 'WigPagePayments',
    components: { OrderDepositHistory },
    props: {
        totalPrice: { type: Number, default: 0 },
    },
    computed: {
        ...mapGetters('orders', { order: gettersTypesOrders.getOrder }),
        ...mapGetters('payments', { getPayments: getterTypes.getPayments }),
        payments: function () {
            return (
                this.getPayments.filter(
                    (payment) => payment.terminated !== 1
                ) || []
            )
        },
        totalDeposit: function () {
            return this.payments.reduce((acc, payment) => {
                return acc + +payment.amount
            }, 0)
        },
        status: function () {
            return !this.totalPrice
                ? 0
                : Math.floor((this.totalDeposit / this.totalPrice) * 100)
        },
        readonly: function () {
            const state = this.order?.state?.toLowerCase() || ''
            return state === 'archived'
        },
    },
    methods: {
        ...mapActions('modals', { onOpen: actionTypes.openPopUp }),
        addPayment: function () {
            this.onOpen(orderDeposit)
        },
        addNotes: function () {
            this.onOpen(orderPaymentsNotes)
        },
    },
}
</script>

<style lang="scss">
.orders-page-payments {
    margin: 16px 0 8px;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 8px;
    padding: 8px 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    &__status {
        position: absolute;
        top: 0;
        right: 0;

        @include for-hebrew {
            right: auto;
            left: 0;
        }
    }

    &__title {
        padding: 8px 0 0;
        font-weight: 400;
        font-size: 14px;
    }

    &__head {
        border-top: 1px solid #d0d0d0;
    }

    &__body {
        position: relative;
        padding: 8px 0;
        min-height: 65px;
    }

    &__foot {
        border-top: 1px solid #d0d0d0;
        display: flex;
        flex-direction: column;
    }

    &__total {
        font-size: 14px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 8px 0;

        p {
            margin: 0;
        }

        &--deposit {
            font-weight: 400;
            border-top: 1px solid #d0d0d0;
            border-bottom: none;
        }
    }

    &__status {
        font-size: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__actions {
        padding-top: 8px;
        border-top: 1px solid #d0d0d0;
        display: flex;
        justify-content: center;
        gap: 16px;
    }

    &__payments-notes {
        font-size: 12px;
    }
}
</style>
