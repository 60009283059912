<template>
    <div class="additional-properties">
        <div ref="properties" class="additional-properties__body">
            <ul class="additional-properties__list">
                <li
                    v-for="(category, index) in propertiesByCategories"
                    :key="index"
                    class="additional-properties__property"
                    :class="{
                        ['is-existed']: categoryHasExisted(category.category),
                    }"
                >
                    <OrdersAdditionalPropertiesCategory
                        :category="category"
                        @property-remove-handle="propertyRemoveHandle"
                    >
                        <div class="additional-properties__add-new-property">
                            <v-text-field
                                ref="titles"
                                v-model="property.title[category.category]"
                                :placeholder="textContent['properties-add']"
                                dense
                                hide-details="auto"
                                @keyup.enter="
                                    () => propertyAddHandle(category.category)
                                "
                            ></v-text-field>
                            <div
                                class="additional-properties__add-new-property-action"
                            >
                                <v-btn
                                    x-small
                                    class="mx-2"
                                    dark
                                    color="#4ca363"
                                    @click="
                                        () =>
                                            propertyAddHandle(category.category)
                                    "
                                >
                                    <v-icon small dark> mdi-plus</v-icon>
                                    {{ textContent['add'] }}
                                </v-btn>
                                <v-btn
                                    v-if="categoryHasExisted(category.category)"
                                    x-small
                                    class="mx-2"
                                    dark
                                    color="#d95a5a"
                                    @click="
                                        () =>
                                            categoryRemoveHandleWithConfirm(
                                                category.category
                                            )
                                    "
                                >
                                    <v-icon small dark> mdi-minus</v-icon>
                                    {{ textContent['remove-category'] }}
                                </v-btn>
                                <v-btn
                                    v-else
                                    x-small
                                    class="mx-2"
                                    dark
                                    color="#d95a5a"
                                    @click="
                                        () =>
                                            categoryRemoveHandle(
                                                category.category
                                            )
                                    "
                                >
                                    <v-icon small dark> mdi-minus</v-icon>
                                    {{ textContent['remove-category'] }}
                                </v-btn>
                            </div>
                        </div>
                    </OrdersAdditionalPropertiesCategory>
                </li>
            </ul>
        </div>
        <div class="additional-properties__foot">
            <p class="additional-properties__add-category-title">
                {{ textContent['add-new-category'] }}
            </p>
            <v-text-field
                v-model="categoryName"
                :placeholder="textContent['category']"
                dense
                hide-details="auto"
            ></v-text-field>
            <div v-if="!readonly" class="additional-properties__foot-actions">
                <v-btn
                    x-small
                    class="mx-2"
                    dark
                    color="#1976d2"
                    @click="categoryAddHandle"
                >
                    {{ textContent['continue'] }}
                </v-btn>
            </div>
            <div
                v-if="!hideActions"
                class="additional-properties__foot-action-extra"
            >
                <v-btn small class="mx-2" dark color="#967adc" @click="onSave">
                    {{ $t('pages.wigPage.slideOuts.save') }}
                </v-btn>
                <v-btn
                    small
                    class="mx-2"
                    dark
                    color="#979ea3"
                    @click="closeHandle"
                >
                    {{ $t('pages.wigPage.slideOuts.close') }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import OrdersAdditionalPropertiesCategory from '@/components/orders/orders-additional-properties-category'

import { find } from 'lodash'
import shortid from 'shortid'
import languageMixin from '@/mixins/language.mixin'
import { mapActions } from 'vuex'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'

export default {
    name: 'OrdersReworkProperties',
    components: { OrdersAdditionalPropertiesCategory },
    mixins: [languageMixin],
    props: {
        reworkProperties: {
            type: Array,
            default: () => [],
        },
        createOrder: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['dispatch-cancel-handle', 'edit-handle', 'close-handle'],
    data() {
        return {
            titles: [],
            propertiesForDeleting: [],
            categoryName: '',
            firstProperty: '',
            property: {
                input: {},
                title: {},
                comment: '',
            },
            panel: false,
            newPropertyIsOpen: [],
            properties: [],
        }
    },
    computed: {
        propertiesByCategories: function () {
            let categories = []
            this.properties.forEach((property) => {
                const categoryExicted = categories
                    .map(({ category }) => category)
                    .includes(property.category)

                if (categoryExicted) {
                    categories = categories.map((item) =>
                        item.category === property.category
                            ? {
                                  ...item,
                                  properties: [...item.properties, property],
                              }
                            : item
                    )
                } else {
                    categories = [
                        ...categories,
                        { category: property.category, properties: [property] },
                    ]
                }
            })
            return categories
        },
        textContent: function () {
            return this.getTextForSelected(
                this.lang,
                'OrdersAdditionalProperties'
            )
        },
    },
    mounted() {
        if (this.createOrder) return
        this.properties = [
            ...this.preparePropertiesForUpdate(this.reworkProperties),
        ]
    },
    methods: {
        ...mapActions('confirmer', {
            confirmHandle: actionTypesConfirmer.confirmHandle,
        }),
        preparePropertiesForUpdate: function (properties) {
            return properties.map(({ id, category, comment, tittle }) => {
                const title = tittle
                return { id, category, title, comment, isNew: false }
            })
        },
        newPropertySwitchHandle: function (index) {
            let found = false

            this.property.title = ''
            this.property.comment = ''

            this.newPropertyIsOpen = this.newPropertyIsOpen.map((item) => {
                found = item.id === index
                return item.id === index
                    ? { ...item, value: !item.value }
                    : { ...item, value: false }
            })

            if (!found)
                this.newPropertyIsOpen = [
                    ...this.newPropertyIsOpen,
                    { id: index, value: true },
                ]
        },
        checkIsOpen: function (index) {
            return find(this.newPropertyIsOpen, { id: index })?.value
        },
        propertyAddHandle: function (category) {
            const comment = this.property.comment
            const title = this.property.title[category]
            const id = shortid()

            this.properties = [
                ...this.properties,
                { id, category, title, comment, isNew: true },
            ]

            this.property.title[category] = ''
            this.property.comment = ''
        },
        propertyRemoveHandle: async function (id) {
            const message = 'Are you sure about deleting this stage?'

            const isNew =
                this.properties.filter((item) => item.id === id)?.[0]?.isNew ||
                false

            if (isNew) {
                this.properties = this.properties.filter((item) => {
                    if (item.id === id && !item.isNew)
                        this.propertiesForDeleting = [
                            ...this.propertiesForDeleting,
                            id,
                        ]
                    return item.id !== id
                })
            } else {
                try {
                    await this.confirmHandle(message)

                    this.properties = this.properties.filter((item) => {
                        if (item.id === id && !item.isNew)
                            this.propertiesForDeleting = [
                                ...this.propertiesForDeleting,
                                id,
                            ]
                        return item.id !== id
                    })
                } catch (e) {
                    console.log(e)
                }
            }
        },
        categoryAddHandle: function () {
            this.property.title = {
                ...this.property.title,
                [this.categoryName]: '',
            }
            this.property.input = {
                ...this.property.input,
                [this.categoryName]: null,
            }

            this.properties = [
                ...this.properties,
                {
                    category: this.categoryName,
                    title: this.firstProperty,
                    comment: '',
                    isNew: true,
                    id: shortid(),
                },
            ]
            this.firstProperty = ''
            this.categoryName = ''
            this.focus()
            this.scrollToLast()
        },
        categoryRemoveHandle: function (category) {
            this.properties = this.properties.filter((item) => {
                if (item.category === category && !item.isNew) {
                    this.propertiesForDeleting = [
                        ...this.propertiesForDeleting,
                        item.id,
                    ]
                }
                return item.category !== category
            })
        },
        categoryRemoveHandleWithConfirm: async function (category) {
            const message = 'Are you sure about deleting this category?'

            try {
                await this.confirmHandle(message)

                this.properties = this.properties.filter((item) => {
                    if (item.category === category && !item.isNew) {
                        this.propertiesForDeleting = [
                            ...this.propertiesForDeleting,
                            item.id,
                        ]
                    }
                    return item.category !== category
                })
            } catch (e) {
                console.log(e)
            }
        },
        focus: async function () {
            this.$nextTick(() => {
                setTimeout(() => {
                    if (!this.$refs.titles) return null
                    this.$refs.titles[this.$refs.titles.length - 1].focus()
                })
            })
        },
        scrollToLast: function () {
            const posY = this.$refs.properties.scrollHeight
            this.$refs.properties.scrollTo(0, posY)
        },
        categoryHasExisted: function (category) {
            return this.properties.reduce((ak, item) => {
                if (item.category !== category) return ak
                return !item?.isNew ? (ak = true) : ak
            }, false)
        },
        onSave: function () {
            this.propertiesByCategories.forEach((item) => {
                if (this.property.title[item.category]) {
                    this.propertyAddHandle(item.category)
                }
            })

            const propertiesForRemoving = this.propertiesForDeleting
            const propertiesForAdding = this.properties.filter(
                ({ title, isNew }) => title && isNew
            )

            this.$emit('edit-handle', {
                propertiesForRemoving,
                propertiesForAdding,
            })
            this.closeHandle()
            return { propertiesForRemoving, propertiesForAdding }
        },
        closeHandle: function () {
            this.$emit('close-handle')
        },
    },
}
</script>

<style lang="scss" scoped>
.additional-properties {
    height: 100%;
    overflow: auto;
    position: relative;

    &__head {
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        padding-bottom: 4px;
        margin-bottom: 15px;
        height: 40px;
    }

    &__title {
        font-size: 20px;
    }

    &__list {
        padding: 0;
    }

    &__property {
        margin-bottom: 10px;
        padding: 10px 15px 5px;
        background: rgba(26, 255, 0, 0.1);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
        border-radius: 8px;

        &.is-existed {
            background: rgba(85, 0, 255, 0.1);
        }
    }

    &__add-category-title {
        margin-bottom: 0;
    }

    &__add-new-property {
        padding: 5px 15px;
    }

    &__add-new-property-title {
        margin-bottom: 7px;
    }

    &__add-new-property-action {
        margin: 10px -8px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__body {
        overflow: auto;
        height: calc(100% - 100px - 16px);

        @include tablet-up {
            padding: 0 16px;
        }
    }

    &__foot {
        background: #cfecff;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-top: 16px;
        padding: 16px;
        border-radius: 8px;
        margin: 16px 0 0;

        @include tablet-large-up {
            margin: 16px 16px 0;
        }
    }

    &__foot-actions {
        button {
            margin: 0 !important;
        }
    }

    &__foot-action-extra {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
    }
}
</style>
