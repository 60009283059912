<template>
    <div class="calendar-main">
        <div class="calendar-main__head">
            <div class="calendar-main__left-action left-action">
                <v-btn
                    class="left-action__btn"
                    :[cusSizes]="true"
                    dense
                    color="#967adc"
                    outlined
                    @click="onPrev"
                >
                    <v-icon
                        >{{ isRtl ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
                    </v-icon>
                    {{ textContent['prev'] }}
                </v-btn>

                <v-btn
                    class="left-action__btn"
                    :[cusSizes]="true"
                    dense
                    color="#967adc"
                    outlined
                    @click="onNext"
                >
                    {{ textContent['next'] }}
                    <v-icon
                        >{{ isRtl ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
                    </v-icon>
                </v-btn>

                <v-btn
                    class="left-action__today"
                    :[cusSizes]="true"
                    dense
                    color="#967adc"
                    outlined
                    @click="moveToCurrent"
                >
                    {{ textContent['today'] }}
                </v-btn>
            </div>
            <div class="calendar-main__title">
                <h3 class="calendar-main__type">
                    {{ text(kind) }}
                </h3>
                <p class="calendar-main__selected-date">
                    {{ dateForHead }}
                </p>
            </div>
            <div class="calendar-main__right-action">
                <template v-if="isMobile">
                    <v-select
                        v-model="type"
                        color="#967adc"
                        dense
                        :items="types"
                        outlined
                    ></v-select>
                </template>
                <template v-else>
                    <v-btn-toggle dark mandatory>
                        <v-btn
                            dark
                            small
                            dense
                            color="#967adc"
                            @click="() => onSwitchType('month')"
                        >
                            {{ textContent['month'] }}
                        </v-btn>
                        <v-btn
                            dark
                            small
                            dense
                            color="#967adc"
                            @click="() => onSwitchType('week')"
                        >
                            {{ textContent['week'] }}
                        </v-btn>
                        <v-btn
                            dark
                            small
                            dense
                            color="#967adc"
                            @click="() => onSwitchType('day')"
                        >
                            {{ textContent['day'] }}
                        </v-btn>
                    </v-btn-toggle>
                </template>
            </div>
        </div>
        <v-calendar
            ref="calendar"
            v-model="value"
            event-start="start_at"
            event-end="endAt"
            :event-margin-bottom="5"
            :event-name="'name'"
            first-time="09:00"
            :event-more="false"
            :interval-count="12"
            :weekdays="[0, 1, 2, 3, 4]"
            min-weeks="1"
            :weekday-format="weekdayFormat"
            :max-days="5"
            :short-intervals="false"
            :start="start"
            :interval-minutes="30"
            :event-overlap-mode="'column'"
            :event-overlap-threshold="60"
            :interval-width="50"
            interval-height="55"
            :events="meetingsWithColor"
            :type="type"
            :event-height="eventHeight"
            :interval-format="intervalFormat"
            @click:event="openEvent"
            @click:day="createMeeting"
            @click:date="selectDay"
            @click:time="createMeeting"
            @change="changeHandle"
        >
            <!--    eslint-disable-next-line -->
            <template #event="{ event, eventParsed }">
                <template v-if="event">
                    <CalendarEventName
                        :event="{
                            hour: eventParsed.start.hour,
                            minute: eventParsed.start.minute,
                            text: event,
                            typeOfCalendar: type,
                            isNewWig: event?.wig?.is_repair !== 1 || false,
                            isRepairWig: event?.wig?.is_repair === 1 || false,
                        }"
                    />
                </template>
            </template>
        </v-calendar>
        <v-menu
            v-model="selectedOpen"
            max-width="200"
            min-width="200"
            :close-on-content-click="false"
            :activator="selectedElement"
        >
            <div class="meeting-more">
                <div class="meeting-more__head">
                    <v-btn icon x-small dense @click="selectedOpen = false">
                        <v-icon small>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <div class="meeting-more__body">
                    <p class="meeting-more__time">{{ selectedTime }}</p>
                    <p v-if="selectedEvent" class="meeting-more__client">
                        {{ clientName }}
                    </p>
                    <p class="meeting-more__phone">
                        {{ toHebrewPhoneFormat(selectedEvent) }}
                    </p>
                    <p v-if="selectedEvent" class="meeting-more__desc">
                        {{ selectedEvent.subject }}
                    </p>
                </div>
                <div class="meeting-more__actions">
                    <v-btn
                        dark
                        x-small
                        dense
                        color="#3bafda"
                        @click="updateHandle"
                    >
                        <v-icon x-small>mdi-square-edit-outline</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="selectedEvent.wig_id"
                        dark
                        x-small
                        dense
                        color="#37bc9b"
                        @click="goToWigs"
                    >
                        <v-icon x-small>mdi-archive-arrow-up-outline</v-icon>
                    </v-btn>
                    <v-btn
                        dark
                        x-small
                        dense
                        color="#f6bb42"
                        @click="goToClient"
                    >
                        <v-icon x-small>mdi-account-arrow-right</v-icon>
                    </v-btn>
                    <v-btn
                        dark
                        x-small
                        dense
                        color="#da4453"
                        @click="deleteHandle"
                    >
                        <v-icon x-small>mdi-delete-forever</v-icon>
                    </v-btn>
                </div>
                <div
                    v-if="selectedEvent?.old_dates?.length > 0"
                    class="meeting-more__old-list"
                >
                    <CalendarOldDatesList
                        :old-dates="selectedEvent.old_dates"
                    />
                </div>
            </div>
        </v-menu>
    </div>
</template>

<script>
import CalendarEventName from '@/components/calendar/calendar-event-name'

import { getRange } from '@/helpers/rangeFromDate'
import { actionTypes } from '@/store/modules/calendar'
import { mapActions, mapGetters } from 'vuex'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { moveToWorkingDay } from '@/components/calendar/calc'
import { parserForTime } from '@/helpers/parser-for-time'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { actionTypes as actionTypesClients } from '@/store/modules/clients'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import { getFullDate, getInMilliseconds } from '@/helpers/getDateInFormat'
import { getterTypes as gettersTypesModals } from '@/store/modules/modals'
import languageMixin from '@/mixins/language.mixin'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import parseFromInternToHePhoneFormat from '@/helpers/parse-from-intern-to-he-phone-format'
import popUpTypes from '@/types/pop-up-types'
import { getterTypesJewishCalendar } from '@/store/modules/jewish-calendar/types'
import CalendarOldDatesList from '@/components/calendar/calendar-old-dates-list.vue'
import { format, parseISO } from 'date-fns'
import wigTransitions from '@/helpers/transitions/wig-transitions'

const { calendarContext } = popUpTypes

export default {
    name: 'CalendarMain',
    components: {
        CalendarOldDatesList,
        CalendarEventName,
    },
    mixins: [languageMixin],
    props: {
        // eslint-disable-next-line vue/require-default-prop
        startData: null,
        meetings: {
            type: Array,
            default: () => [],
        },
        typeOfMeetings: {
            type: String,
            default: 'orders',
        },
        typeOfFormat: {
            type: String,
            default: 'month',
        },
    },
    emits: ['load-range', 'create-meeting', 'update-handle', 'select-day'],
    data() {
        return {
            selectedMeetingId: null,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            value: undefined,
            range: {
                start: {
                    year: null,
                    month: null,
                    day: null,
                },
                end: {
                    year: null,
                    month: null,
                    day: null,
                },
            },
            type: 'month',
            types: [
                { text: 'month', value: 'month' },
                { text: 'week', value: 'week' },
                { text: 'day', value: 'day' },
            ],
        }
    },
    computed: {
        ...mapGetters('jewishCalendar', {
            getJewishDates: getterTypesJewishCalendar.getJewishDates,
        }),
        ...mapGetters('modals', {
            checkIsActive: gettersTypesModals.isActive,
            payload: gettersTypesModals.payload,
        }),
        isActive: function () {
            return this.checkIsActive(calendarContext)
        },
        months: function () {
            const en = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
            const he = [
                'יָנוּאָר',
                'פברואר',
                'מרץ',
                'אַפּרִיל',
                'מאי',
                'יוני',
                'יולי',
                'אוגוסט',
                'סֶפּטֶמבֶּר',
                'אוֹקְטוֹבֶּר',
                'נוֹבֶמבֶּר',
                'דֵצֶמבֶּר',
            ]
            return this.isRtl ? he : en
        },
        monthsShort: function () {
            const en = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ]
            const he = [
                'יָנוּאָר',
                'פברואר',
                'מרץ',
                'אַפּרִיל',
                'מאי',
                'יוני',
                'יולי',
                'אוגוסט',
                'סֶפּטֶמבֶּר',
                'אוֹקְטוֹבֶּר',
                'נוֹבֶמבֶּר',
                'דֵצֶמבֶּר',
            ]
            return this.isRtl ? he : en
        },
        eventHeight: function () {
            return this.type === 'day' ? 43 : 20
        },
        cusSizes: function () {
            return this.isMobile ? 'normal' : 'x-small'
        },
        currentDate: function () {
            const current = moveToWorkingDay()
            return {
                year: current.getFullYear(),
                month: current.getMonth(),
                day: current.getDate(),
            }
        },
        kind: function () {
            switch (this.typeOfMeetings.toLowerCase()) {
                case 'pick_up_new':
                    return 'Pick Up New'
                case 'pick_up_repairs':
                    return 'Pick Up Repairs'
                case 'repairs':
                    return 'Repairs'
                case 'us_agent':
                    return 'US Agent'
                default:
                    return 'Orders'
            }
        },
        meetingsWithColor: function () {
            const meetings = this.meetings.map((meeting) => {
                let color = ''
                let iconColor = ''
                switch (meeting.calendar.toLowerCase()) {
                    case 'orders':
                        color = '#967adc'
                        iconColor = '#967adc'
                        break
                    case 'pick-up new wigs':
                        color = '#37bc9b'
                        iconColor = '#690E00'
                        break
                    case 'pick-up repairs':
                    case 'repairs':
                        color = '#f6bb42'
                        iconColor = '#82261A'
                        break
                    case 'us_agent':
                        color = '#b05454'
                        iconColor = ''
                        break
                    default:
                        color = 'primary'
                }
                return {
                    ...meeting,
                    color,
                    iconColor,
                    ['event-width']: 40,
                }
            })
            const lang = this.isRtl ? 'he' : 'en'

            return [...meetings, ...this.getJewishDates(lang)].map(
                (meeting) => {
                    const isHebrow =
                        meeting.category && meeting.category === 'holidays'
                    const duration = 1800000
                    let startAt = meeting['start_at']
                        .replace(/-|\s|:/gi, ',')
                        .split(',')

                    startAt[1] -= 1

                    const endAt = isHebrow
                        ? meeting['start_at']
                        : getFullDate(+new Date(...startAt) + duration)

                    const wigIsReady = [
                        'Ready New Wig',
                        'Delivered Wigs',
                        'Storage',
                        'Ready Repair Wigs',
                    ].includes(meeting?.wig?.state)

                    return {
                        ...meeting,
                        endAt,
                        wigIsReady,
                    }
                }
            )
        },
        selectedTime: function () {
            if (!this.selectedEvent) return ''
            const date = new Date(
                getInMilliseconds(this.selectedEvent.start_at)
            )

            const h = date.getHours()
            let m = date.getMinutes()
            m = m.toString().length === 1 ? '0' + m : m
            return `${h}:${m}`
        },
        start: function () {
            const { year, month, day } = this.currentDate
            return this.startData
                ? new Date(this.startData)
                : new Date(year, month, day)
        },
        dateForHead: function () {
            const start = this.range.start
            const end = this.range.end

            switch (this.type) {
                case 'week':
                    return `${this.monthsShort[start.month - 1]} ${
                        start.day
                    } - ${
                        start.month !== end.month
                            ? this.monthsShort[end.month - 1] + ' '
                            : ''
                    }${end.day}, ${start.year}`
                case 'day':
                    return `${this.months[start.month - 1]} ${start.day}, ${
                        start.year
                    }`
                default:
                    return `${this.months[start.month - 1]} ${start.year}`
            }
        },
        clientName() {
            return `${this.selectedEvent?.client?.name} ${this.selectedEvent?.client?.surname}`
        },
        currentDay() {
            return this.range?.start?.date || ''
        },
    },
    watch: {
        typeOfMeetings: function () {
            this.onChange()
        },
        currentDay: function (value) {
            this.$emit('select-day', value)
        },
        isActive: function (value) {
            if (value) this.type = this.typeOfFormat
        },
        startData: function () {
            this.value = null
            this.onChange()
        },
    },
    mounted() {
        this.onChange()
    },
    methods: {
        ...mapActions('calendar', { onDelete: actionTypes.deleteOne }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapActions('confirmer', {
            confrimHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('clients', { getClient: actionTypesClients.getClient }),
        ...mapActions('orders', { getOrder: actionTypesOrders.loadOrder }),
        text: function (text) {
            return this.textContent[regularToSnakeCase(text)]
        },
        intervalFormat: function (interval) {
            return interval.time
        },
        eventName: function (name) {
            return name
        },
        onSwitchType: function (type) {
            this.type = type
        },
        onPrev: function () {
            this.$refs.calendar.prev()
            this.onChange()
        },
        onNext: function () {
            this.$refs.calendar.next()
            this.onChange()
        },
        moveToCurrent: function () {
            this.value = this.start
        },
        onChange: function () {
            let range

            if (this.value) {
                range = format(parseISO(this.value, 1), 'yyyy-MM')
            } else if (!this.value && this.start) {
                range = format(this.start, 'yyyy-MM')
            } else {
                return
            }

            const detail = { type: this.typeOfMeetings, range }
            this.$emit('load-range', detail)
        },
        changeHandle: function ({ start, end }) {
            this.range.start = start
            this.range.end = end
        },
        selectDay: function (object) {
            this.$emit('select-day', object.date)
            this.createMeeting(object)
        },
        createMeeting: function ({ date, hour, minute }) {
            let time = null
            if (hour && minute) time = parserForTime({ hour, minute })
            this.$emit('create-meeting', { date, time })
        },

        openEvent: function ({ nativeEvent, event }) {
            nativeEvent.stopPropagation()
            if (event.category === 'holidays') return

            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() =>
                    requestAnimationFrame(() => (this.selectedOpen = true))
                )
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }
        },
        deleteHandle: function () {
            const id = this.selectedEvent.id
            const range = getRange(this.selectedEvent.start_at)
            const type = this.typeOfMeetings
            const wigId = this.selectedEvent.wig_id || ''
            this.confrimHandle(this.textContent['message-delete-meeting'])
                .then(() => {
                    this.onDelete({ range, type, id, wigId })
                        .then(() => {
                            this.selectedOpen = false
                            this.success('Meeting has been canceled')
                        })
                        .catch(() => {})
                })
                .catch(() => {})
        },
        updateHandle: function () {
            this.$emit('update-handle', this.selectedEvent)
        },
        goToWigs: function () {
            wigTransitions.moveToWigPageInNewTab(this.selectedEvent.wig_id)
        },
        goToClient: function () {
            this.getClient(this.selectedEvent.client_id)
        },
        weekdayFormat: function ({ weekday }) {
            const test = this.isRtl
                ? ['א׳', 'ב׳', 'ג׳', 'ד׳', 'ה׳']
                : ['sun', 'mon', 'tue', 'wed', 'thu']
            return test[weekday]
        },
        toHebrewPhoneFormat(meeting) {
            const phone = meeting?.client?.phone
            return parseFromInternToHePhoneFormat(phone)
        },
    },
}
</script>

<style lang="scss" scoped>
.calendar-main {
    &__head {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        @include mobile-xs-up {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__left-action {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media print {
            display: none;
        }
    }

    &__right-action {
        @media print {
            display: none;
        }
    }

    &__title {
        order: -1;
        text-align: center;
        margin: 0 0 10px;
        @include mobile-xs-up {
            order: 0;
        }
    }
}

.meeting-more {
    padding: 5px;
    border-radius: 5px;
    background: $color-primary-light;
    width: 100%;
    font-size: 13px;

    &__head {
        display: flex;
        justify-content: flex-end;
    }

    &__time {
        font-weight: 700;
        font-size: 1.1em;
        margin-bottom: 5px;
    }

    &__body {
        padding: 0 0 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #eceeef;
    }

    &__actions {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 5px;
    }

    &__old-list {
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid #eceeef;
    }
}

.left-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media print {
        display: none;
    }

    &__btn {
        width: 48%;
        @include mobile-xs-up {
            width: auto;
        }
    }

    &__today {
        width: 100%;
        margin: 0 0 5px;
        order: -1;
        @include mobile-xs-up {
            order: 0;
            width: auto;
            margin: 0 0 0 10px;
        }
    }
}
</style>
