<template>
    <v-dialog
        v-model="isActive"
        width="700"
        persistent
        @click:outside.prevent="closeHandleWithConfirm"
    >
        <div>
            <OrderModalWebcam
                :is-active-data="modals.webcam"
                @close-handle="webcamModalClose"
                @on-capture="onCaptureHandle"
            />
            <div
                v-if="isActive"
                class="add-order"
                :class="{ ['is-loading']: isLoading }"
            >
                <div class="add-order__header">
                    <h3 class="add-order__title">
                        {{
                            isRepair
                                ? textContent['add-repair']
                                : textContent['add-order']
                        }}
                    </h3>
                    <v-btn
                        icon
                        color="#55595c"
                        @click="closeHandleWithConfirm"
                        data-test-id="wigs--add-new-wig-form--header-close-btn"
                    >
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                </div>
                <div class="add-order__body">
                    <div class="add-order__client">
                        <template v-if="selectedId">
                            <ClientFormSelect
                                :selected-id="general.fields['client_id']"
                                @select-handle="selectedClientHandle"
                            />
                        </template>
                        <template v-else>
                            <ClientFormSelect
                                :selected-id="general.fields['client_id']"
                                @select-handle="selectedClientHandle"
                            />
                        </template>
                    </div>
                    <div class="add-order__wig-properties">
                        <template v-if="isRepair">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header color="#967adc">
                                        <h3
                                            class="add-order__additional-properties"
                                        >
                                            {{
                                                textContent['repair-properties']
                                            }}
                                        </h3>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <OrdersAdditionalProperties
                                            :on-dispatch="onDispatch"
                                            create-order
                                            @dispatch-cancel-handle="
                                                () => {
                                                    onDispatch = 0
                                                }
                                            "
                                        />
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </template>
                        <template v-else>
                            <OrderFormProperties
                                :data-properties="properties"
                            />
                        </template>
                    </div>
                    <div class="add-order__wig-general">
                        <OrderFormGeneral :data="general" />
                    </div>
                    <div class="add-order__wig-files">
                        <v-file-input
                            v-model="files"
                            :label="textContent['photo']"
                            outlined
                            dense
                            multiple
                            :error-messages="general.errors['files']"
                            data-test-id="wigs--add-new-wig-form--photo-file-input"
                            @change="checkFile"
                        ></v-file-input>
                        <v-btn dark color="#967adc" @click="webcamModalOpen">
                            Take a photo
                        </v-btn>
                    </div>
                </div>
                <div class="add-order__footer">
                    <div class="add-order__actions">
                        <v-btn
                            dark
                            color="#55595c"
                            @click="closeHandleWithConfirm"
                        >
                            {{ textContent['close'] }}
                        </v-btn>
                        <v-btn
                            :disabled="isSubmitting || hasError"
                            :dark="!(isSubmitting || hasError)"
                            color="#967adc"
                            data-test-id="wigs--add-new-wig-form--footer-submit-btn"
                            @click="addOrderSubmit"
                        >
                            {{ textContent['add'] }}
                        </v-btn>
                    </div>
                </div>
                <div v-if="isLoading" class="add-order__loader">
                    <v-progress-circular
                        size="100"
                        width="10"
                        indeterminate
                        color="#967adc"
                    ></v-progress-circular>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import ClientFormSelect from '@/components/forms/client-form-select'
import OrderFormGeneral from '@/components/forms/order-form-general'
import OrderFormProperties from '@/components/forms/order-form-properties'
import OrderModalWebcam from '@/components/modals/order-modal-webcam'

import { mapGetters, mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/clients'
import { getterTypes as getterTypesModals } from '@/store/modules/modals'
import {
    actionTypes as actionTypesOrders,
    getterTypes,
} from '@/store/modules/orders'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import parserForOrderErrors from '@/helpers/parser-for-order-errors'

import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import languageMixin from '@/mixins/language.mixin'
import convertBase64ToFile from '@/helpers/convert-base64-to-file'
import OrdersAdditionalProperties from '@/components/orders/orders-additional-properties'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import wigTransitions from '@/helpers/transitions/wig-transitions'

const { orderAdd, clientUpdate } = popUpTypes

export default {
    name: 'OrderModalAdd',
    components: {
        OrdersAdditionalProperties,
        ClientFormSelect,
        OrderFormGeneral,
        OrderFormProperties,
        OrderModalWebcam,
    },
    mixins: [modalsMixin, languageMixin],
    data() {
        return {
            isLoading: false,
            onDispatch: 0,
            selfName: orderAdd,
            clientFullName: '',
            barcode: 'empty',
            clientTabs: 0,
            selectedClient: null,
            modals: {
                webcam: false,
            },
            newClient: {
                fields: {
                    name: '',
                    surname: '',
                    email: '',
                    phone: '',
                    additionalPhone: '',
                    country: '',
                    city: '',
                },
                errors: {},
            },
            general: {
                fields: {
                    ['client_id']: '',
                    ['totalPrice']: '',
                    ['barcode']: '',
                    ['info']: '',
                    ['is_paid']: true,
                    ['details']: '',
                    ['user_generated_id']: '',
                    ['is_reword']: false,
                    ['visible']: true,
                    ['shippingInfo']: '',
                },
                errors: {},
            },
            properties: {
                fields: {
                    ['wigType']: '',
                    ['capSize']: '',
                    ['capAdjustment']: '',
                    ['topType']: '',
                    ['topTypeComments']: '',
                    ['front']: '',
                    ['frontComments']: '',
                    ['baseColor']: '',
                    ['skinColor']: '',
                    ['highlightsColor']: '',
                    ['highlightsColorSelect']: '',
                    ['typeOfHighlights']: '',
                    ['skinTopHighlights']: '',
                    ['frontColor']: '',
                    ['highlightsColoring']: '',
                    ['highlightsColoringSelect']: '',
                    ['colorComments']: '',
                    ['lengthFromNape']: '',
                    ['lengthFromTop']: '',
                    ['sideLength']: '',
                    ['lengthComments']: '',
                    ['hairTextureOnWefts']: '',
                    ['hairTextureComments']: '',
                    ['wigFullness']: '',
                    ['wigFullnessAdditionalAmount']: '',
                    ['wigFullnessComments']: '',
                    ['weight']: '',
                },
                errors: {},
            },
            files: [],
        }
    },
    computed: {
        ...mapGetters('orders', { isSubmitting: getterTypes.isSubmitting }),
        ...mapGetters('modals', {
            getActiveModals: getterTypesModals.getModals,
        }),
        isRepair: function () {
            return this.extraData?.isRepair
        },
        selectedId: function () {
            return this.extraData?.clientId
        },
        hasError: function () {
            return !!Object.values(this.general.errors).filter((i) => i)[0]
        },
        isActive: function () {
            return (
                this.getActiveModals
                    .map(({ name }) => name)
                    .includes(this.selfName) ||
                (this.getActiveModals
                    .map(({ name }) => name)
                    .includes(this.selfName) &&
                    this.getActiveModals
                        .map(({ name }) => name)
                        .includes(clientUpdate))
            )
        },
    },
    watch: {
        selectedId: function () {
            if (!this.selectedId) return
            this.general.fields['client_id'] = this.selectedId
        },
    },
    mounted() {},
    methods: {
        ...mapActions('confirmer', {
            confirmHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('clients', {
            addClient: actionTypes.addClient,
        }),
        ...mapActions('orders', {
            loadProperties: actionTypesOrders.loadProperties,
            createOrder: actionTypesOrders.createOrder,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        selectedClientHandle: function ({ name, id }) {
            this.general.fields['client_id'] = id
            delete this.general.errors['client_id']
            this.general.errors = { ...this.general.errors }
            this.clientFullName = name
        },
        addOrderSubmit: async function () {
            try {
                if (this.clientTabs === 1 && !this.general.fields['client_id'])
                    this.general.fields['client_id'] =
                        (
                            await this.addClient({
                                ...this.newClient.fields,
                                visible: true,
                            })
                        )?.id || null

                let res = {
                    ['is_repair']: this.isRepair,
                }

                let properties = {}

                Object.keys(this.properties.fields).forEach((field) => {
                    if (!this.properties.fields[field]) return
                    properties = {
                        ...properties,
                        [field]: this.properties.fields[field],
                    }
                })

                Object.keys(this.general.fields).forEach((field) => {
                    if (!this.general.fields[field]) return
                    res = { ...res, [field]: this.general.fields[field] }
                })

                res = { ...res, properties, state: 'Draft' }

                const newOrder = {
                    ['barcode']: this.general.fields.barcode,
                    ['baseColor']: this.properties.fields['baseColor'],
                    ['client']: this.clientFullName,
                    ['lengthFromNape']:
                        this.properties.fields['lengthFromNape'],
                    ['state']: 'Draft',
                    ['is_repair']: this.isRepair,
                }

                const form = new FormData()
                const json = JSON.stringify(res)

                form.append('data', json)

                if (this.files.length !== 0) {
                    this.files.forEach((file) => {
                        console.log(file)
                        form.append('files[]', file)
                    })
                }

                this.createOrder({ form, newOrder })
                    .then((data) => {
                        if (this.isRepair) {
                            this.onDispatch = data.id
                        }
                        setTimeout(() => {
                            this.closeHandle()
                            this.success('Order has been created')
                            this.clearAllFields()
                        }, 200)
                        return data
                    })
                    .then((createdWig) => {
                        wigTransitions.moveToWigPage(createdWig.id)
                    })
                    .catch((er) => {
                        Object.keys(er).forEach((key) => {
                            this.general.errors = {
                                ...this.general.errors,
                                [parserForOrderErrors(key)]: er[key],
                            }
                        })
                    })
            } catch (e) {
                this.newClient.errors = { ...this.newClient.errors, ...e }
            }
        },
        clearAllFields: function () {
            Object.keys(this.general.fields).forEach((field) => {
                this.general.fields[field] = ''
            })
            Object.keys(this.properties.fields).forEach((field) => {
                this.properties.fields[field] = ''
            })
            this.clientFullName = ''
            this.selectedClient = null
            this.files = []
        },
        checkFile: function () {
            this.general.errors['files'] = ''
        },
        webcamModalClose: function () {
            this.modals.webcam = false
        },
        webcamModalOpen: function () {
            this.modals.webcam = true
        },
        onCaptureHandle: function (file) {
            this.webcamModalClose()
            this.files = [...this.files, convertBase64ToFile(file)]
        },
        closeHandleWithConfirm: async function () {
            try {
                await this.confirmHandle(
                    this.textContent['message-cancel-create-wig']
                )
                this.clearAllFields()
                this.closeHandle()
            } catch (e) {
                console.log('cancel')
            }
        },
    },
}
</script>

<style scoped lang="scss">
.add-order {
    background: $color-primary-light;
    position: relative;

    &.is-loading {
        overflow: hidden;
        max-height: 90vh;
    }

    &__header {
        padding: 20px 15px;
        border-bottom: 1px solid #eceeef;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-weight: 400;
        font-size: 18px;
        font-family: $font-muli;

        @include for-hebrew {
            font-family: sans-serif;
        }
    }

    &__additional-properties {
        width: 100%;
        text-align: center;
        font-weight: 400;
        color: #fff;
    }

    &__body {
        padding: 0 15px;
    }

    &__wig-properties {
        padding: 10px 10px 0;
    }

    &__client {
        padding: 16px 16px 0;
    }

    &__footer {
        padding: 0 0 15px;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        padding: 0 30px 10px;
    }

    &__wig-files {
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        padding: 0 15px;

        @include tablet-up {
            flex-direction: row;
        }

        button {
            margin: -5px 0 15px;
            @include tablet-up {
                margin: 2px 0 0 10px;
            }
        }
    }

    &__loader {
        position: absolute;
        background: rgba(255, 255, 255, 0.35);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.tabs {
    &__title {
    }

    &__icon {
    }

    &__title-text {
    }

    &__body {
        padding: 15px 10px 0;
        background: #f7f7f9;
    }

    &__sub-title {
        margin-bottom: 15px;
        font-size: 16px;
    }
}
</style>
