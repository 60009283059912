<template>
    <div class="event-name">
        <p v-if="!isHebrew" class="event-name__time">{{ time }}</p>
        <a
            v-if="event.text.link"
            :href="event.text.link"
            target="_blank"
            class="event-name__text is-hebrew"
        >
            {{
                isHebrew
                    ? event.text.text
                    : `${event.text.client.name} ${event.text.client.surname}`
            }}
        </a>
        <div
            v-else
            class="event-name__text"
            :class="{ ['is-hebrew']: isHebrew }"
        >
            <p>
                {{
                    isHebrew
                        ? event.text.text
                        : `${event.text.client.name} ${event.text.client.surname}`
                }}
            </p>
            <p v-if="isDay || isWeek">
                <template v-if="isDay">
                    <p class="event-name__phone">
                        {{ toHebrewPhoneFormat(event) }}<br />
                    </p>
                </template>
                {{ event.text.subject }}
            </p>
        </div>
        <div v-if="event.text.wigIsReady" class="event-name__done-icon">
            <v-icon :color="event.text.iconColor" small
                >mdi-check-outline
            </v-icon>
        </div>
        <div
            v-if="event?.text?.calendar?.toLowerCase() === 'us_agent'"
            class="event-name__done-icon event-name__done-icon--append"
        >
            <v-icon v-if="event.isRepairWig" color="white" small>
                mdi-wrench-outline
            </v-icon>

            <v-icon v-else-if="event.isNewWig" color="white" small>
                mdi-new-box
            </v-icon>
        </div>
    </div>
</template>

<script>
import parseFromInternToHePhoneFormat from '@/helpers/parse-from-intern-to-he-phone-format'

export default {
    name: 'CalendarEventName',
    props: {
        event: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        isDay: function () {
            return this.event.typeOfCalendar === 'day'
        },
        isWeek: function () {
            return this.event.typeOfCalendar === 'week'
        },
        isHebrew: function () {
            return this.hour === '00'
        },
        hour() {
            return this.event.hour.toString().length === 1
                ? `0${this.event.hour}`
                : this.event.hour
        },
        minute() {
            return this.event.minute.toString().length === 1
                ? `${this.event.minute}0`
                : this.event.minute
        },
        time() {
            return `${this.hour}:${this.minute}`
        },
    },
    methods: {
        toHebrewPhoneFormat(event) {
            const phone = event.text?.client?.phone
            if (!phone) return
            return parseFromInternToHePhoneFormat(phone)
        },
    },
}
</script>

<style lang="scss" scoped>
.event-name {
    padding: 0 5px;
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;

    &__time {
        margin: 0 5px 0 0;
        height: 100%;

        @include for-hebrew {
            margin: 0 0 0 5px;
        }
    }

    &__text {
        color: $color-primary-light;
        text-decoration: none;
        height: 100%;

        &.is-hebrew {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;
            font-size: 1.2em;
            font-weight: 700;
        }

        p {
            padding: 0;
            margin: 0;
        }
    }

    &__phone {
        direction: ltr;
    }

    &__done-icon {
        margin-left: 15px;
    }
}
</style>
