import api from '@/api/users'

const state = () => ({
    users: [],
    isFetching: false,
})

export const getterTypes = {
    getAllUsers: '[users] Get all users',
    isFetching: '[users] Is fetching',
}

export const mutationTypes = {
    loadAllUsers: '[users] Load all users',
    deleteUser: '[users] Delete user',
    addUser: '[users] Add new one user',
    replaceUser: '[users] Replace user',
    changeRole: '[users] Change role',
    changeCurrentUserName: '[users] Change current user name',
    startFetching: '[users] Start fetching',
    stopFetching: '[users] Stop fetching',
}

export const actionTypes = {
    loadAllUsers: '[users] Load all users',
    addNewUser: '[users] Add new user',
    deleteUser: '[users] Delete user',
    loadOneUser: '[users] Load new one user',
    changeRole: '[users] Change role',
    changePassword: '[users] Change password',
    changeName: '[users] Change name',
    changeCredentials: '[users] Change password and name',
    getReport: '[users] Get report',
    getReportByPayments: '[users] Get report about payments',
    editUserProfile: '[users] Edit user profile',
}

const mutations = {
    [mutationTypes.loadAllUsers](state, users) {
        state.users = users
    },
    [mutationTypes.deleteUser](state, id) {
        state.users = state.users.filter((user) => user.id !== id)
    },
    [mutationTypes.startFetching](state) {
        state.isFetching = true
    },
    [mutationTypes.stopFetching](state) {
        state.isFetching = false
    },
    [mutationTypes.addUser](state, newUser) {
        let added = false
        state.users = state.users.map((user) => {
            if (user.id !== newUser.id) {
                return user
            } else {
                added = true
                return newUser
            }
        })
        if (!added) state.users = [...state.users, newUser]
    },
    [mutationTypes.changeRole](state, { id, newRole }) {
        state.users = state.users.map((user) =>
            user.id === id ? { ...user, role: newRole } : user
        )
    },
}

const getters = {
    [getterTypes.getAllUsers]: ({ users }) => users,
    [getterTypes.isFetching]: ({ isFetching }) => isFetching,
}

const actions = {
    [actionTypes.loadAllUsers]({ commit }, count = 1000) {
        commit(mutationTypes.startFetching)
        return new Promise((res) => {
            api.user
                .getAll(count)
                .then((data) => {
                    commit(mutationTypes.loadAllUsers, data.data)
                    res(data)
                    commit(mutationTypes.stopFetching)
                })
                .catch(function (error) {
                    console.log(error)
                    commit(mutationTypes.stopFetching)
                })
        })
    },
    [actionTypes.loadOneUser]({ commit }, id) {
        return new Promise((res) => {
            api.user
                .getUser(id)
                .then((data) => {
                    commit(mutationTypes.addUser, data.data)
                    res(data)
                })
                .catch(function (error) {
                    console.log(error)
                })
        })
    },
    [actionTypes.addNewUser]({ dispatch }, credentials) {
        return new Promise((res, rej) => {
            api.user
                .addUser(credentials)
                .then((data) => {
                    res(data)
                    dispatch(actionTypes.loadOneUser, data)
                })
                .catch(function (error) {
                    rej(error.response.data.errors)
                })
        })
    },
    [actionTypes.changeRole]({ dispatch }, credentials) {
        return new Promise((res) => {
            api.user
                .changeRole(credentials)
                .then((data) => {
                    res(data)
                    dispatch(actionTypes.loadOneUser, credentials.id)
                })
                .catch(function (error) {
                    console.log(error)
                })
        })
    },
    [actionTypes.changeName]({ dispatch }, credentials) {
        return new Promise((res, rej) => {
            api.user
                .changeName(credentials)
                .then((data) => {
                    res(data)
                    dispatch(actionTypes.loadOneUser, credentials.id)
                })
                .catch(function (error) {
                    console.log(error)
                    rej(error.response.data.errors)
                })
        })
    },
    [actionTypes.changePassword]({ dispatch }, credentials) {
        return new Promise((res, rej) => {
            api.user
                .changePassword(credentials)
                .then((data) => {
                    res(data)
                    dispatch(actionTypes.loadOneUser, credentials.id)
                })
                .catch(function (error) {
                    console.log(error)
                    rej(error.response.data.errors)
                })
        })
    },
    [actionTypes.changeCredentials]({ dispatch }, credentials) {
        const changePassword =
            credentials.password.length !== 0
                ? new Promise((res, rej) => {
                      dispatch(actionTypes.changePassword, credentials)
                          .then((data) => {
                              res(data)
                          })
                          .catch((error) => {
                              rej(error)
                          })
                  })
                : null

        const changeName =
            credentials.nickName.length !== 0
                ? new Promise((res, rej) => {
                      dispatch(actionTypes.changeName, credentials)
                          .then((data) => {
                              res(data)
                          })
                          .catch((error) => {
                              rej(error)
                          })
                  })
                : null

        const promises = [changePassword, changeName].filter((p) => p)
        return Promise.all(promises)
    },
    [actionTypes.deleteUser]({ commit }, id) {
        return new Promise((res) => {
            api.user
                .deleteUser(id)
                .then((data) => {
                    res(data)
                    commit(mutationTypes.deleteUser, id)
                })
                .catch(function (error) {
                    console.log(error)
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.getReport]({ commit }, data) {
        return new Promise((r) => {
            api.user
                .report(data)
                .then(({ data }) => {
                    r(data.url)
                })
                .catch((er) => {
                    console.log(er)
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.getReportByPayments]({ commit }, data) {
        return new Promise((r) => {
            api.user
                .reportByPayments(data)
                .then(({ data }) => {
                    r(data.url)
                })
                .catch((er) => {
                    console.log(er)
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.editUserProfile]({ commit }, credentials) {
        return new Promise((res, rej) => {
            api.user
                .changeData(credentials)
                .then((r) => {
                    res(r)
                })
                .catch((err) => {
                    console.log(err)
                    rej(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
