<template>
    <div class="client-cell">
        <p class="client-cell__client-name">
            {{ clientDetails.name }}
        </p>
        <p class="client-cell__client-phone">
            {{ clientDetails.phone }}
        </p>
        <template v-if="wig.owner.role === 'USA_MANAGER'">
            <p class="client-cell__usa-agent-name">|</p>
            <p class="client-cell__usa-agent-name">
                {{ wig.owner.nickName }}
            </p>
        </template>
    </div>
</template>

<script>
import clientNameTrim from '@/helpers/clientNameTrimer'

export default {
    name: 'WigsDetailsPrintPageClientCell',
    props: {
        wig: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        clientDetails: function () {
            const { clientName, clientPhone } = clientNameTrim(this.wig.client)
            return { name: clientName, phone: clientPhone }
        },
    },
}
</script>

<style lang="scss">
.client-cell {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.1em;

    &__client-name,
    &__client-phone,
    &__usa-agent-name {
        margin-bottom: 0 !important;
        font-weight: 700;
    }

    &__client-name {
        padding: 0;
    }

    &__client-phone {
        direction: ltr;
    }

    &__usa-agent-name {
        padding: 0;
        font-weight: 700;
    }
}
</style>
