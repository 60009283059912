<template>
    <section class="clients">
        <SubHeader :title="textContent.title" :crumbs="textContent.crumbs" />
        <div class="clients__body">
            <div class="clients__search">
                <ClientsSearch
                    :searched="searched"
                    :total="total"
                    @search-handle="searchUpdate"
                    @filter-handle="filterUpdate"
                />
            </div>
            <div class="clients__list">
                <ClientsTable
                    :clients="sortedClients"
                    @context-handle="contextHandle"
                />
            </div>
        </div>
    </section>
</template>

<script>
import SubHeader from '@/components/common/sub-header'
import ClientsSearch from '@/components/clients/clients-search'
import ClientsTable from '@/components/clients/clients-table'

import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/clients'

import { filter, sortBy } from 'lodash'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import languageMixin from '@/mixins/language.mixin'

export default {
    name: 'ClientsList',
    components: {
        SubHeader,
        ClientsSearch,
        ClientsTable,
    },
    mixins: [languageMixin],
    data() {
        return {
            selectedUser: {
                id: null,
                isActive: false,
            },
            count: 10,
            searched: '',
            sorted: 'by-name',
            activeFilters: ['existing'],
        }
    },
    computed: {
        idIsAlready: function () {
            return this.$route?.params?.id || undefined
        },
        filtredClients: function () {
            const clients = filter(this.getAllClients, (client) => {
                return this.activeFilters.includes(client.type)
            })
            if (this.searched.length === 0) return clients
            return filter(clients, (client) => {
                const phoneInLocalFormat = client.phone.replace(/(\+972 )/, '0')
                return (
                    client.full_name
                        .toLowerCase()
                        .includes(this.searched.toLowerCase()) ||
                    client.phone.includes(this.searched) ||
                    phoneInLocalFormat.includes(this.searched)
                )
            })
        },
        clientModalContextIsActive: function () {
            const atLeast =
                this.addAndChangeClient.isActive || this.deleteClient.isActive
            return this.selectedUser.isActive && !atLeast
        },
        sortedClients: function () {
            switch (this.sorted) {
                case 'by-name-reverse':
                    return sortBy(this.filtredClients, ['full_name']).reverse()
                case 'by-phone':
                    return sortBy(this.filtredClients, ['phone'])
                case 'by-phone-reverse':
                    return sortBy(this.filtredClients, ['phone']).reverse()
                default:
                    return sortBy(this.filtredClients, ['full_name'])
            }
        },
        ...mapGetters('clients', {
            getAllClients: getterTypes.getAllClients,
            total: getterTypes.total,
        }),
    },
    mounted() {
        this.loadAllClients()
        if (this.idIsAlready) this.openClient(this.idIsAlready)
    },
    methods: {
        ...mapActions('clients', {
            loadAllClients: actionTypes.getAllClients,
            loadAllDeleted: actionTypes.getAllDeleted,
            getClient: actionTypes.getClient,
            deleteClient: actionTypes.deleteClient,
        }),
        ...mapActions('confirmer', {
            confrimHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        searchUpdate: function (str) {
            this.searched = str
        },
        filterUpdate: function (value) {
            this.activeFilters = value
        },
        contextHandle: function (id) {
            this.getClient(id)
        },
    },
}
</script>

<style scoped lang="scss">
.clients {
    &__body {
        display: flex;
        flex-direction: column;

        @include tablet-up {
            flex-direction: row;
        }
    }

    &__search {
        background: $color-primary-light;
        min-width: 200px;
        height: fit-content;
        box-shadow: 0 2px 1px rgb(0 0 0 / 5%);
        margin: 20px 0;

        @include tablet-up {
            margin: 0 20px 0 0;

            @include for-hebrew {
                margin: 0 0 0 20px;
            }
        }
    }

    &__list {
        background: $color-primary-light;
        box-shadow: 0 2px 1px rgb(0 0 0 / 5%);
        flex-shrink: 0;
        flex-grow: 1;
        padding: 20px;
        overflow: auto;

        @include tablet-up {
            max-width: calc(100% - 230px);
            margin-right: 15px;
        }
    }
}
</style>
