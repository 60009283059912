export default {
    header: 'Total price',
    paymentsHistory: 'Payments history',
    paymentsNotes: 'Payments notes',
    totalDeposit: 'Total deposit',
    noDepositYet: 'No deposit has been made',
    updatePaymentsNotes: 'Add/Edit Payment Notes',
    actions: {
        addPayment: 'Add Payment',
        addNote: 'Add/Edit notes',
    },
}
