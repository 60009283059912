export default {
    returnLink: 'חזרה לרשימת פאות',
    header: 'מידע כללי',
    items: {
        client: 'לקוחה',
        barcode: 'ברקוד',
        additionalWigID: 'מזהה פאה נוסף',
        details: 'פרטים',
        state: 'סטטוס',
        status: 'שלבי ייצור',
        storageNotes: 'הערה לאחסון',
        completeDate: 'מוכן לתאריך',
        manager: 'מנהל',
        created: 'נוצר',
        updated: 'עודכן',
    },
    extraActions: {
        changeClient: 'החלפת לקוחה',
        changeManager: 'החלפת מנהל',
        scheduleAppointment: 'קביעת תור',
        scheduleAppointmentForUSA: 'קביעת תור ללקוחה ארהב',
        showScheduledAppointment: 'הצג תור',
        rescheduleForRework: 'תור לאחר עבודה מחדש',
    },
}
