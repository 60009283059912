<template>
    <div class="category">
        <div class="category__wrapper">
            <h3 class="category__title">{{ category.category }}</h3>
            <ul class="category__properties">
                <li
                    v-for="(property, index) in properties"
                    :key="index"
                    class="category__property"
                >
                    <p class="category__property-title">
                        {{ property.title }}
                    </p>
                    <div class="category__remove-btn">
                        <v-btn
                            icon
                            color="pink"
                            @click="() => propertyRemoveHandle(property.id)"
                        >
                            <v-icon x-small color="#d95a5a">
                                mdi-close-circle
                            </v-icon>
                        </v-btn>
                    </div>
                </li>
            </ul>
        </div>
        <div class="category__add-new">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrdersAdditionalPropertiesCategory',
    props: {
        category: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['property-remove-handle'],
    data() {
        return {}
    },

    computed: {
        properties: function () {
            return this.category.properties.filter(({ title }) => title)
        },
    },
    methods: {
        propertyRemoveHandle: function (id) {
            this.$emit('property-remove-handle', id)
        },
    },
}
</script>

<style lang="scss" scoped>
.category {
    &__wrapper {
        padding: 0 5px;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            display: block;
            height: calc(100% - 77px);
            width: 2px;
            top: 10px;
            left: 0;
            //background: rgb(150, 122, 220);
        }
    }

    &__properties {
    }

    &__property {
        position: relative;
        margin-bottom: 4px;
        cursor: pointer;
        opacity: 0.8;
        transition: all 150ms linear;
        border-bottom: 1px dotted #ddd;
        padding: 5px 17px;
        font-size: 13px;
        font-weight: 400;

        &:hover {
            opacity: unset;

            .category__remove-btn {
                opacity: unset;
            }
        }

        &:before {
            position: absolute;
            content: '';
            display: block;
            height: 2px;
            width: 30px;
            top: 18px;
            left: -35px;
            //background: rgb(150, 122, 220);
        }
    }

    &__title {
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 0;
    }

    &__property-title {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
        padding-right: 20px;

        @include for-hebrew {
            padding-right: 0;
            padding-left: 20px;
        }
    }

    &__comment {
        font-size: 13px;
    }

    &__add-new {
    }

    &__remove-btn {
        position: absolute;
        top: 0;
        right: 5px;

        @include for-hebrew {
            right: auto;
            left: 5px;
        }
    }
}
</style>
