import noRecordsYet from './no-records-yet'
import clientMeetingList from './clientMeetingList'
import wigStates from './wig-states'
import warningMessages from './warning-messages'
import wigTypes from './wig-types'

export default {
    noRecordsYet,
    clientMeetingList,
    wigStates,
    warningMessages,
    wigTypes,
}
