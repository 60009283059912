const regularToSnakeCase = (str) => {
    const hasSpace = /\s/.test(str)
    const hasUnderline = /_/.test(str)
    return !str || typeof str === 'number'
        ? ''
        : hasSpace
        ? str.replace(/\s/g, '-').toLowerCase()
        : hasUnderline
        ? str.replace(/_/g, '-').toLowerCase()
        : str.toLowerCase()
}

export default regularToSnakeCase
