<template>
    <div>
        <div v-if="isActive" class="tabs">
            <ul class="tabs__list">
                <li
                    v-for="(tab, i) in panels"
                    :key="i"
                    class="tabs__item tabs__item--is-active"
                    @click="() => goToHandle(i)"
                >
                    {{ tab.name }}
                </li>
            </ul>
        </div>
        <ClientModalContext />
        <ClientModalAddChange />
        <CalendarSubLayout />
        <CalendarModalAddForUsa />
        <OrderModalContext />
        <OrderModalAdd />
        <OrderModalPropertiesUpdate />
        <OrderModalGeneralUpdate />
        <OrderModalStatusUpdate />
        <OrderModalDepositUpdate />
        <OrderModalStorage />
        <OrderModalAssignCreator />
        <OrderModalClientUpdate />
        <OrdersAdditionalPropertiesCategory />
        <ReportModalMain />
        <UsersModalEditProfile />
        <ExtraRequestTester />
        <ClientModalSelect />
        <OrderModalUSATools />
        <CommonModal />
        <OrderModalPaymentsNotes />
    </div>
</template>

<script>
import ClientModalContext from '@/components/modals/client-modal-context'
import ClientModalAddChange from '@/components/modals/client-modal-add-change'
import CalendarSubLayout from '@/components/modals/calendar-sub-layout'
import OrderModalContext from '@/components/modals/order-modal-context'
import OrderModalAdd from '@/components/modals/order-modal-add'
import OrderModalPropertiesUpdate from '@/components/modals/order-modal-properties-update'
import OrderModalGeneralUpdate from '@/components/modals/order-modal-general-update'
import OrderModalStatusUpdate from '@/components/modals/order-modal-status-update'
import OrderModalDepositUpdate from '@/components/modals/order-modal-deposit-update'
import OrderModalStorage from '@/components/modals/order-modal-storage'
import OrderModalAssignCreator from '@/components/modals/order-modal-assign-creator'
import OrderModalClientUpdate from '@/components/modals/order-modal-client-update'
import ReportModalMain from '@/components/modals/report-modal-main'
import ExtraRequestTester from '@/components/modals/extra-request-tester'
import ClientModalSelect from '@/components/modals/client-modal-select'
import OrdersAdditionalPropertiesCategory from '@/components/modals/order-modal-additional-properties-update'
import OrderModalUSATools from '@/components/modals/order-modal-usa-tools'

import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/modals'
import { actionTypes as actionTypesLanguage } from '@/store/modules/language-pack'
import UsersModalEditProfile from '@/components/modals/users-modal-edit-profile'
import CalendarModalAddForUsa from '@/components/modals/calendar-modal-add-for-usa'
import CommonModal from '@/components/modals/common-modal.vue'
import OrderModalPaymentsNotes from '@/components/modals/order-modal-payments-note.vue'

export default {
    name: 'PopUpContainer',
    components: {
        OrderModalPaymentsNotes,
        CommonModal,
        CalendarModalAddForUsa,
        UsersModalEditProfile,
        ClientModalContext,
        ClientModalAddChange,
        CalendarSubLayout,
        OrderModalContext,
        OrderModalAdd,
        OrderModalPropertiesUpdate,
        OrderModalGeneralUpdate,
        OrderModalStatusUpdate,
        OrderModalDepositUpdate,
        OrderModalStorage,
        OrderModalAssignCreator,
        OrderModalClientUpdate,
        ReportModalMain,
        ExtraRequestTester,
        OrdersAdditionalPropertiesCategory,
        ClientModalSelect,
        OrderModalUSATools,
    },
    computed: {
        ...mapGetters('modals', { panels: getterTypes.getModals }),
        isActive: function () {
            return this.panels.length >= 1
        },
    },
    methods: {
        ...mapActions('modals', { goTo: actionTypes.goTo }),
        ...mapActions('languagePack', {
            loadLanguage: actionTypesLanguage.getLanguagePack,
        }),
        goToHandle: function (m) {
            this.goTo(m + 1)
        },
    },
}
</script>
<style lang="scss" scoped>
.tabs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 2000;
    background: linear-gradient(180deg, #fff 0%, #e7e7e7 100%);

    @media print {
        display: none;
    }

    &__list {
        display: flex;
    }

    &__item {
        background: #967adc;
        border-radius: 0 0 5px 5px;
        padding: 5px 10px;
        text-align: center;
        color: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        transition: transform 50ms linear;
        border: 1px solid transparent;
        border-top: none;

        &:hover {
            transform: translateY(-3px);
        }

        &:last-child {
            background: #fff;
            border-color: #967adc;
            color: #967adc;
            font-weight: 600;
        }

        & + & {
            margin-left: 3px;
        }
    }
}
</style>
