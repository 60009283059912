<template>
    <div class="clients-table">
        <div class="clients-table__header">
            <h4 class="clients-table__title">{{ textContent.list }}</h4>
            <v-btn
                color="#967adc"
                small
                dark
                @click="addClient"
                data-test-id="clients--add-client-btn"
            >
                <i class="icon-plus-small"></i>
                <span class="clients-table__btn-text">{{
                    textContent['add-client']
                }}</span>
            </v-btn>
        </div>
        <div v-if="!isMobile" class="clients-table__actions">
            <p>{{ textContent.show }}</p>
            <div class="clients-table__counter">
                <select
                    v-model="countToShow"
                    class="clients-table__counter-select"
                >
                    <option v-for="item in counts" :key="item" :value="item">
                        {{ item }}
                    </option>
                </select>
                <i class="icon-select-arrows"></i>
            </div>
            <p>{{ textContent.items }}</p>
        </div>
        <div class="clients-table__table">
            <v-data-table
                calculate-widths
                mobile-breakpoint="767"
                :headers="headers"
                :page="page"
                :items="clientsToVisible"
                :hide-default-footer="!isMobile"
                :items-per-page-options="counts"
                :items-per-page="countToShow"
                :items-per-page-text="textContent['clients-per-page']"
                @page-count="pageCount = $event"
                @update:page="updatePage"
                @click:row="contextHandle"
            ></v-data-table>
            <template v-if="!isMobile">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="7"
                    color="#3bafda"
                ></v-pagination>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/modals'

import popUpTypes from '@/types/pop-up-types'
import phoneNumberView from '@/helpers/phone-number-view'
import languageMixin from '@/mixins/language.mixin'
import { sortBy } from 'lodash'

const { clientUpdate } = popUpTypes

export default {
    name: 'ClientsTable',
    mixins: [languageMixin],
    props: {
        clients: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['context-handle', 'add-client'],
    data() {
        return {
            windowWidth: window.innerWidth,
            page: 1,
            pageCount: 0,
            counts: [10, 25, 50, 100],
            countToShow: 10,
        }
    },
    computed: {
        isMobile: function () {
            return this.windowWidth < 768
        },
        clientsToVisible: function () {
            const res = this.clients.map((client) => {
                const createdAt = new Date(client.created_at).getTime()
                return {
                    ...client,
                    phone: phoneNumberView(client.phone),
                    createdAt,
                }
            })

            return sortBy(res, 'createdAt').reverse()
        },
        headers: function () {
            return [
                { text: this.textContent?.name, value: 'full_name' },
                {
                    text: this.textContent?.phone,
                    value: 'phone',
                    width: '25%',
                },
            ]
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        ...mapActions('modals', { onOpen: actionTypes.openPopUp }),
        updatePage: function (page) {
            this.page = page
        },
        contextHandle: function ({ id }) {
            this.$emit('context-handle', id)
        },
        addClient: function () {
            this.onOpen(clientUpdate)
        },
        onResize() {
            this.windowWidth = window.innerWidth
        },
    },
}
</script>

<style scoped lang="scss">
.clients-table {
    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        @include tablet-up {
            margin-bottom: 0;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        margin-top: 35px;
        font-size: 14px;
    }

    &__counter {
        margin: 0 10px;
        position: relative;

        i {
            position: absolute;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
        }
    }

    &__counter-select {
        font-size: 12px;
        padding: 10px 24px 10px 10px;
        border: 1px solid rgba(0, 0, 0, 0.35);
        border-radius: 4px;
        outline: none;
        transition: all 150ms ease-in-out;

        &:focus {
            border: 1px solid #3bafda;
        }
    }

    &__title {
        font-weight: 500;
    }

    &__btn-text {
        text-transform: none;
    }
}

.table {
    margin-top: 15px;
    width: 100%;
    table-layout: fixed;
    border: 1px solid #000;
    border-collapse: collapse;
    color: #55595c;
    font-size: 15px;

    th,
    td {
        border: 1px solid #e3ebf3;
        padding: 5px 0 5px 25px;
    }

    &__header {
        border-bottom: 3px solid #e3ebf3;
    }

    &__row {
        &--title {
            width: 300px;
        }
    }

    &__column {
        text-align: left;

        &--name {
        }

        &--phone {
            width: 25%;
            min-width: 135px;
        }
    }
}
</style>
