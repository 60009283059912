<template>
    <ul class="deposit" data-test-id="wig-page--payments--history-table">
        <li
            v-for="({ amount, source, comment, createdAt }, i) in payments"
            :key="i"
            class="deposit__item"
        >
            <p class="deposit__date">
                <DateCell :value="createdAt" />
            </p>
            <p class="deposit__amount">{{ amount }}</p>
            <p class="deposit__extra">({{ text(source) }}, {{ comment }})</p>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/payments'
import languageMixin from '@/mixins/language.mixin'
import { getFullDayFirstFull } from '@/helpers/getDateInFormat'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import DateCell from '@/components/common/cells/date-cell.vue'

export default {
    name: 'OrderDepositHistory',
    components: { DateCell },
    mixins: [languageMixin],
    computed: {
        ...mapGetters('payments', { getPayments: getterTypes.getPayments }),
        payments: function () {
            return this.getPayments
                .filter((payment) => payment.terminated !== 1)
                .map((payment) => {
                    return {
                        ...payment,
                        createdAt: payment.created_at,
                    }
                })
        },
        textContent: function () {
            return (
                this.getTextForSelected(this.lang, 'OrderModalDepositUpdate') ||
                ''
            )
        },
    },
    methods: {
        text: function (text) {
            regularToSnakeCase()
            return this.textContent[regularToSnakeCase(text)] || `*${text}`
        },
        dateFormat: function (date) {
            return date ? getFullDayFirstFull(date) : ''
        },
    },
}
</script>

<style scoped lang="scss">
.deposit {
    padding: 0 0 5px;

    &__item {
        display: flex;
        color: #55595c;
        font-size: 12px;

        & + & {
            margin-top: 4px;
        }

        p {
            margin: 0 !important;
        }
    }

    &__date {
        min-width: 120px;
    }

    &__amount {
        min-width: 34px;
        margin-right: 3px;
        font-weight: 700;
        color: #000;
    }

    &__extra {
        font-size: 12px;
    }
}
</style>
