import axios from '@/api/axios'

export default {
    user: {
        login: (credentials) =>
            axios
                .post('/api/login/', {
                    ...credentials,
                })
                .then((res) => res.data),
        getCurrentUser: () =>
            axios.get('/base/api/login/user').then((res) => res.data),
        getAllNotifications: () =>
            axios.get('/base/api/notifications/getAll').then((r) => r),
        readNotification: (id) =>
            axios.post('/base/api/notifications/read', { id }).then((r) => r),
    },
}
