export default {
    header: 'מחיר כולל',
    paymentsHistory: 'היסטוריית תשלומים',
    paymentsNotes: 'הערות לתשלום',
    updatePaymentsNotes: 'לעדכן את החשבון לתשלום',
    totalDeposit: 'תשלומים',
    noDepositYet: 'לא שולמה מקדמה',
    actions: {
        addPayment: 'הוספת תשלום',
        addNote: 'הוספת\\עריכת הערה',
    },
}
