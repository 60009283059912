import generalList from './general-list'
import ordersPagePayments from './orders-page-payments'
import logs from './logs'
import slideOuts from './slide-outs'

export default {
    returnBtn: 'חזרה לרשימת פאות',
    workflowActionsMenu: {
        header: 'שלבי העבודה-פעולות',
        update: 'עדכון ביצוע',
        edit: 'עריכת שלבים',
        editNewWigProperties: 'עריכת פרטים',
    },
    tabs: {
        reworkWorkflow: 'שלבי עבודה חוזרת',
        workflow: 'שלבי העבודה',
        information: 'מידע',
        meetings: 'תורים',
        logs: 'היסטוריה',
    },
    mainActions: {
        mobileButton: 'פעולות',
        print: 'הדפסה',
        restoreWig: 'שיוך ללקוחה חדשה',
        moveToStock: 'העבירי למלאי',
        delivery: 'מסירה',
        productionSteps: 'שלבי ייצור',
        edit: 'ערוך',
        rework: 'החזרה לעבודה',
        moveToDraft: 'העבירי להמתנה',
        cancelOrder: 'בטלי הזמנה',
        delete: 'מחיקה',
        'production-start': 'התחלת ייצור',
        'production-complete': 'הייצור הושלם',
        'repair-complete': 'התיקון הושלם',
        storage: 'פאות באחסון',
        message: 'האם את בטוחה שאת רוצה לשנות סטטוס?',
    },
    generalList,
    ordersPagePayments,
    logs,
    slideOuts,
}
