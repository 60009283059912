export default {
    headers: {
        createdAt: 'נוצר בתאריך',
        createdBy: 'נוצר על ידי',
        doneBy: 'בוצע על ידי',
        prevClient: 'לקוחה קודמת',
        newClient: 'לקוחה חדשה',
        prevManager: 'מנהל קודם',
        newManager: 'מנהל חדש',
        doneAt: 'בוצע בתאריך',
        action: 'פעולה',
        source: 'מצב מקורי',
        createdWig: 'פאת היעד',
        sourceWig: 'פאת המקור',
        current: 'Current',
    },
    tables: {
        general: {
            header: 'כללי',
        },
        changesClient: {
            header: 'היסטוריית לקוחות',
        },
        changesManager: {
            header: 'היסטוריית של מנהלים',
        },
        changesState: {
            header: 'היסטוריית סטטוסים',
        },
        sourceHistory: {
            header: 'היסטוריית פאה',
            sourceType: {
                ['new_wig']: 'פאה חדשה',
                ['from_stock']: 'ממלאי',
            },
        },
    },
}
