export default {
    headers: {
        createdAt: 'Created At',
        createdBy: 'Created By',
        doneBy: 'Done By',
        prevClient: 'Previous client',
        newClient: 'New client',
        prevManager: 'Previous manager',
        newManager: 'New manager',
        doneAt: 'Done At',
        action: 'Action',
        source: 'Source',
        createdWig: 'Created wig',
        sourceWig: 'Source wig',
        current: 'Current',
    },
    tables: {
        general: {
            header: 'General',
        },
        changesClient: {
            header: 'Client Change Logs',
        },
        changesManager: {
            header: 'Manager Change Logs',
        },
        changesState: {
            header: 'Status change logs',
        },
        sourceHistory: {
            header: 'Source history',
            sourceType: {
                ['new_wig']: 'new wig',
                ['from_stock']: 'from stock',
            },
        },
    },
}
