<template>
    <main
        class="main__wrapper"
        :class="{ 'main__wrapper--is-load': pageIsReady }"
    >
        <PopUpContainer />
        <SlideOutContainer />
        <NotificationSuccess />
        <LoaderProgressCircle />
        <component :is="type" />
        <div
            class="main__side-bar-wrapper"
            :class="{ ['is-active']: menuIsActive }"
        >
            <div class="main__side-bar">
                <MenuMain />
            </div>
        </div>
        <div class="main__body">
            <header class="main__header">
                <HeaderPrimary />
            </header>
            <KeepAlive include="Orders">
                <router-view> </router-view>
            </KeepAlive>
            <!--            <Footer />-->
        </div>
        <div v-if="devData.isVisible" class="dev-data">
            <p class="dev-data__extra-current-hash">
                sha:
                <a
                    :href="`https://gitlab.com/F1nn1e/crm-wigs-front/-/commit/${devData.hash}`"
                    target="_blank"
                    >{{ devData.hash }}</a
                >
            </p>
            <p class="dev-data__extra-current-built-at">
                built at: {{ devData.builtAT }}
            </p>
        </div>
    </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getterTypes, mutationTypes } from '@/store/modules/main-menu'
import { getterTypes as getterTypesConfirmer } from '@/store/modules/confirmer'
import { actionTypes as actionTypesAuth } from '@/store/modules/auth'
import { actionTypes as actionTypesRoles } from '@/store/modules/roles'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'

import HeaderPrimary from '@/components/common/header-primary'
import MenuMain from '@/components/nav/menu-main'
import NotificationSuccess from '@/components/common/notification-success'
import Footer from '@/components/common/footer'
import { actionTypes } from '@/store/modules/users'
import { actionTypes as actionTypesClients } from '@/store/modules/clients'
import Confirmer from '@/components/common/confirmer'
import ConfirmerWithPrompt from '@/components/common/confirmer-with-prompt'
import LoaderProgressCircle from '@/components/common/loader-progress-circle'
import PopUpContainer from '@/components/container/pop-up-container'
import { getterTypes as getterTypesLanguagePack } from '@/store/modules/language-pack'
import SlideOutContainer from '@/components/container/slide-out-container.vue'

export default {
    name: 'MainLayout',
    components: {
        SlideOutContainer,
        PopUpContainer,
        Confirmer,
        ConfirmerWithPrompt,
        HeaderPrimary,
        MenuMain,
        NotificationSuccess,
        LoaderProgressCircle,
        Footer,
    },
    data() {
        return {
            devData: {
                isVisible: false,
                branch: '',
                builtAT: '',
                hash: '',
            },
        }
    },
    computed: {
        ...mapGetters('confirmer', {
            getType: getterTypesConfirmer.getType,
        }),
        ...mapGetters('languagePack', {
            pageIsReady: getterTypesLanguagePack.pageIsReady,
        }),
        type: function () {
            switch (this.getType) {
                case 'with-prompt':
                    return 'ConfirmerWithPrompt'
                case 'regular':
                default:
                    return 'Confirmer'
            }
        },
        ...mapGetters('mainMenu', { menuIsActive: getterTypes.getStatus }),
        allowedAdmin: function () {
            return this.getAllowed(['admin', 'manager'])
        },
        allowedUsa: function () {
            return this.getAllowed(['admin', 'manager', 'usa_manager'])
        },
        checkDevMode: function () {
            return (
                window.location.host === 'wigs.qualiteamdev.com' &&
                !window.localStorage.getItem('debugging')
            )
        },
    },
    watch: {
        allowedAdmin: function (param) {
            if (param) {
                this.getRoles()
                this.loadAllClients()
            }
        },
        allowedUsa: function (param) {
            if (param) {
                this.getRoles()
                this.loadAllClients()
            }
        },
    },
    mounted() {
        this.dataLoading()
        this.devData = {
            isVisible: process.env.VUE_APP_BUILD_DATA_DOMAIN === 'dev',
            branch: process.env.VUE_APP_BUILD_DATA_CURRENT_BRANCH,
            builtAT: process.env.VUE_APP_BUILD_DATA_BUILT_AT,
            hash: process.env.VUE_APP_BUILD_DATA_HASH,
        }
    },
    methods: {
        ...mapActions('auth', {
            getCurrentUser: actionTypesAuth.getCurrentUser,
            logout: actionTypesAuth.logout,
        }),
        ...mapActions('roles', { getRoles: actionTypesRoles.loadAllRoles }),
        ...mapMutations('mainMenu', { toggleMenu: mutationTypes.toggle }),
        ...mapActions('clients', {
            loadAllClients: actionTypesClients.getAllClients,
        }),
        ...mapActions('orders', {
            loadAllOrders: actionTypesOrders.loadAll,
        }),

        dataLoading: async function () {
            await this.getCurrentUser()
            await this.loadAllOrders()
        },
        logoutHandle: function () {
            this.logout().then(() => {
                this.$router.replace({ name: 'Login' })
            })
        },
    },
}
</script>

<style scoped lang="scss">
@keyframes fade-in {
    0% {
        transform: translateY(-150%);
    }

    100% {
        transform: translateY(0);
    }
}

.animation-container {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: yellow;
        transform-origin: left;
        background: url('~@/assets/common/logo-bg.png');
        background-size: 230% auto;
        background-position: 11.5% center;
        background-repeat: no-repeat;
    }

    &:before {
        animation: open-left 2000ms ease-in-out 3000ms 1 both;
    }

    &:after {
        left: auto;
        right: 0;
        transform-origin: right;
        background-size: 230% auto;
        background-position: 89.5% center;
        animation: open-right 2000ms ease-in-out 3000ms 1 both;
    }
}

@keyframes open-left {
    0% {
        transform: perspective(1500px) rotateY(0deg);
        opacity: unset;
    }

    75% {
        opacity: unset;
    }

    100% {
        opacity: 0;
        transform: perspective(1500px) rotateY(90deg);
    }
}

@keyframes open-right {
    0% {
        transform: perspective(1500px) rotateY(0deg);
        opacity: unset;
    }

    75% {
        opacity: unset;
    }

    100% {
        opacity: 0;
        transform: perspective(1500px) rotateY(-90deg);
    }
}

.main {
    &__wrapper {
        display: flex;
        width: 100%;
        min-height: 100vh;
        position: relative;
        opacity: 0;
        transition: opacity 500ms ease-in-out;
        overflow: hidden;

        @include tablet-up {
            overflow: visible;
        }

        &--is-load {
            opacity: unset;
        }
    }

    &__side-bar-wrapper {
        position: fixed;
        top: 75px;
        left: 0;
        width: 260px;
        height: calc(100vh - 75px);
        transition: all ease 300ms;
        z-index: 101;
        transform: translateX(-105%);

        @include tablet-up {
            top: auto;
            height: auto;
            z-index: 0;
            transform: translateX(0);
            position: relative;
            width: 60px;
        }

        &.is-active {
            transform: translateX(0);
            @include tablet-up {
                width: 240px;
            }
        }
    }

    &__side-bar {
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        height: 100vh;
        background: $color-for-side-bar;
    }

    &__header {
        position: sticky;
        z-index: 100;
        top: 0;
        height: 75px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
        background: $color-primary-light;
        transform: translateY(-150%);
        animation: fade-in 750ms ease-in-out both 500ms;
        padding-right: 0;

        @include tablet-up {
            padding-right: 16px;
        }

        @media print {
            display: none;
        }
    }

    &__body {
        width: 100px;
        position: relative;
        flex-grow: 1;
        background: $color-for-body-primary;
        height: fit-content;
        min-height: 100vh;

        section {
            padding: 40px 10px 0;
        }
    }

    &__footer {
    }
}

.dev-data {
    display: none;
    position: fixed;
    right: 15px;
    bottom: 15px;
    background: rgba(255, 255, 255, 0.35);
    padding: 8px;
    font-size: 11px;

    @include desktop-md-up {
        display: block;
    }

    p {
        margin: 5px;
    }

    a {
        cursor: pointer;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
}
</style>
