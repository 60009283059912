import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import users from '@/store/modules/users'
import clients from '@/store/modules/clients'
import roles from '@/store/modules/roles'
import countries from '@/store/modules/countries'
import mainMenu from '@/store/modules/main-menu'
import modalNotifications from '@/store/modules/modal-notifications'
import dashboard from '@/store/modules/dashboard'
import orders from '@/store/modules/orders'
import orderRepairsStages from '@/store/modules/order-repairs-stages'
import payments from '@/store/modules/payments'
import status from '@/store/modules/status'
import calendar from '@/store/modules/calendar'
import jewishCalendar from '@/store/modules/jewish-calendar'
import confirmer from '@/store/modules/confirmer'
import configuration from '@/store/modules/configuration'
import languagePack from '@/store/modules/language-pack'
import modalLoaderProgress from '@/store/modules/modal-loader-progress'
import modals from '@/store/modules/modals'
import report from '@/store/modules/report'
import ordersReworkProperties from '@/store/modules/orders-rework-properties'
import slideOuts from '@/store/modules/slide-outs'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        mainMenu,
        users,
        clients,
        roles,
        countries,
        modalNotifications,
        dashboard,
        orders,
        orderRepairsStages,
        payments,
        status,
        calendar,
        jewishCalendar,
        confirmer,
        configuration,
        languagePack,
        modalLoaderProgress,
        modals,
        report,
        ordersReworkProperties,
        slideOuts,
    },
})
