var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"meetings"},_vm._l((_vm.meetingsList),function({
            id,
            startAt,
            calendar,
            comment,
            createdAt,
            createdBy,
        }){return _c('li',{key:id,staticClass:"meetings__item",on:{"click":() => {
                _vm.goToSelectedMeeting({ startAt, calendar })
            }}},[_c('div',{staticClass:"meetings__body"},[_c('div',{staticClass:"meetings__headline"},[_c('div',{staticClass:"meetings__icon"},[_c('v-icon',{attrs:{"color":"#967adc"}},[_vm._v("mdi-calendar-clock")])],1),_c('p',[_c('DateCell',{attrs:{"value":startAt,"utc":false}})],1)]),_c('p',{staticClass:"meetings__comment"},[_vm._v(" "+_vm._s(comment)+" ")])]),_c('div',{staticClass:"meetings__foot"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('shared.clientMeetingList.createdAt'))+": "),_c('span',{staticClass:"date-container"},[_c('DateCell',{attrs:{"value":createdAt}})],1)]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('shared.clientMeetingList.createdBy'))+": "),_c('span',[_vm._v(_vm._s(createdBy))])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }