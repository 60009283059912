<template>
    <section class="dashboard">
        <SubHeader :title="textContent.title" :crumbs="textContent.crumbs" />
        <div class="dashboard__body">
            <ul class="dashboard__totally totally">
                <li
                    v-if="getAllowed(['admin', 'manager'])"
                    class="totally__item"
                >
                    <h4 class="totally__title">{{ textContent.clients }}</h4>
                    <p class="totally__count">
                        {{ totalClientAnimated }}
                    </p>
                </li>
                <li class="totally__item">
                    <h4 class="totally__title">
                        {{ textContent['orders-total'] }}
                    </h4>
                    <p class="totally__count">
                        {{ totalOrdersAnimated }}
                    </p>
                </li>
                <li class="totally__item">
                    <h4 class="totally__title">
                        {{ textContent['orders-in-progress'] }}
                    </h4>
                    <p class="totally__count">
                        {{ totalProgressAnimated }}
                    </p>
                </li>
            </ul>
            <div class="dashboard__search search">
                <h4 class="dashboard__sub-title">{{ textContent.search }}</h4>
                <div class="search__columns">
                    <div
                        v-if="getAllowed(['admin', 'manager'])"
                        class="search__item"
                    >
                        <p class="search__title">{{ textContent.clients }}</p>
                        <div class="search__input-wrapper">
                            <v-autocomplete
                                v-model="search.client"
                                :items="clients"
                                dense
                                outlined
                                :label="textContent.clients"
                                item-color="#967adc"
                                hide-details
                            >
                            </v-autocomplete>
                        </div>
                        <v-btn color="#967adc" @click="goToSelectedClient">
                            <span class="btn-text">{{ textContent.open }}</span>
                        </v-btn>
                    </div>
                </div>
            </div>
            <div class="dashboard__your-orders your-orders">
                <div class="your-orders__action">
                    <h4 class="dashboard__sub-title">
                        {{ textContent['your-orders-list'] }}
                    </h4>
                    <v-btn disabled color="#000">{{ textContent.scan }}</v-btn>
                </div>
                <div class="your-orders__table table">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="text-left">#</th>
                                <th class="text-left">
                                    {{ textContent.state }}
                                </th>
                                <th class="text-left">
                                    {{ textContent.client }}
                                </th>
                                <th class="text-left">
                                    {{ textContent.manager }}
                                </th>
                                <th class="text-left">
                                    {{ textContent.info }}
                                </th>
                                <th class="text-left">
                                    {{ textContent.repair }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in yourOrders"
                                :key="item.id"
                                class="table__row"
                                :class="{
                                    'is-active': item.id === 1,
                                    [itemRowBackground(item['state'])]: true,
                                }"
                                @click="() => goToSelectedOrder(item.id)"
                            >
                                <td>{{ item['id'] }}</td>
                                <td>{{ item['state'] }}</td>
                                <td>{{ item['client'] }}</td>
                                <td>{{ item['manager'] }}</td>
                                <td>{{ item['info'] }}</td>
                                <td>
                                    {{ item['Repair'] === 1 ? 'yes' : 'no' }}
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SubHeader from '@/components/common/sub-header'
import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/dashboard'
import {
    actionTypes as actionTypesClients,
    getterTypes as getterTypesClients,
} from '@/store/modules/clients'
import {
    actionTypes as actionTypesOrders,
    getterTypes as getterTypesOrders,
} from '@/store/modules/orders'

import { gsap } from 'gsap'

import languageMixin from '@/mixins/language.mixin'
import { getInMilliseconds } from '@/helpers/getDateInFormat'
import { sortBy } from 'lodash'

import wigTransitions from '@/helpers/transitions/wig-transitions'

export default {
    name: 'Home',
    components: {
        SubHeader,
    },
    mixins: [languageMixin],
    data() {
        return {
            search: {
                order: 0,
                client: 0,
            },
            totalClient: 0,
            totalOrders: 0,
            totalProgress: 0,
        }
    },
    computed: {
        ...mapGetters('dashboard', {
            totally: getterTypes.getTotally,
            getYourOrders: getterTypes.getYourOrders,
        }),
        ...mapGetters('clients', {
            getAllClients: getterTypesClients.getAllClients,
        }),
        ...mapGetters('orders', {
            getAllOrders: getterTypesOrders.getAll,
        }),
        yourOrders: function () {
            const orders = this.getYourOrders.map((order) => ({
                ...order,
                date: getInMilliseconds(order.created_at),
            }))
            return sortBy(orders, ['date']).reverse()
        },
        totalClientData: function () {
            return this.totally.clients.count
        },
        totalOrdersData: function () {
            return this.totally.ordersTotal.count
        },
        totalProgressData: function () {
            return this.totally.ordersInProgress.count
        },
        totalClientAnimated: function () {
            return this.totalClient.toFixed(0) === '0'
                ? this.totalClientData
                : this.totalClient.toFixed(0)
        },
        totalOrdersAnimated: function () {
            return this.totalOrders.toFixed(0) === '0'
                ? this.totalOrdersData
                : this.totalOrders.toFixed(0)
        },
        totalProgressAnimated: function () {
            return this.totalProgress.toFixed(0) === '0'
                ? this.totalProgressData
                : this.totalProgress.toFixed(0)
        },
        orders: function () {
            return this.getAllOrders.map((orders) => ({
                text: `${orders['client']}`,
                value: { id: orders.id, client: orders['client'] },
            }))
        },
        clients: function () {
            return this.getAllClients.map((client) => ({
                text: `${client['full_name']} ${client['phone']}`,
                value: client.id,
            }))
        },
        allowed: function () {
            return this.getAllowed(['admin', 'manager'])
        },
    },
    watch: {
        totalClientData: function (newValue) {
            gsap.to(this.$data, { duration: 1, totalClient: newValue })
        },
        totalOrdersData: function (newValue) {
            gsap.to(this.$data, { duration: 1, totalOrders: newValue })
        },
        totalProgressData: function (newValue) {
            gsap.to(this.$data, { duration: 1, totalProgress: newValue })
        },
        allowed: function (param) {
            if (param) {
                this.loadAllClients()
            }
        },
    },
    mounted() {
        this.getTotally()
        this.loadAllOrders()
    },
    methods: {
        ...mapActions('dashboard', { getTotally: actionTypes.getAllTotally }),
        ...mapActions('clients', {
            loadAllClients: actionTypesClients.getAllClients,
            getClient: actionTypesClients.getClient,
        }),
        ...mapActions('orders', {
            loadAllOrders: actionTypesOrders.loadAll,
            getOrder: actionTypesOrders.loadOrder,
        }),
        selectOrderHandle: function () {
            if (!this.search?.order?.id) return null
            this.goToSelectedOrder(this.search.order.id)
        },
        goToSelectedClient: function () {
            this.getClient(this.search.client)
        },
        goToSelectedOrder: function (id) {
            wigTransitions.moveToWigPage(id)
        },
        itemRowBackground: function (state) {
            let classBackground = ''
            const condition = state?.toLowerCase()

            switch (condition) {
                case 'wigs in production':
                    classBackground = 'table__row--orange'
                    break
                case 'repair wigs':
                    classBackground = 'table__row--yellow'
                    break
                case 'ready repair wigs':
                    classBackground = 'table__row--light-blue'
                    break
                case 'ready new wig':
                    classBackground = 'table__row--green'
                    break
                case 'cancelled wigs':
                    classBackground = 'table__row--red'
                    break
                case 'storage':
                    classBackground = 'table__row--blue'
                    break
                case 'delivered wigs':
                    classBackground = 'table__row--light-brown'
                    break
                case 'wigs in stock':
                    classBackground = 'table__row--pink'
                    break
                case 'rework':
                    classBackground = 'table__row--dark-orange'
                    break
                case 'archived':
                    classBackground = 'orders__row--silver'
                    break
                case 'draft':
                default:
                    classBackground = 'white'
            }

            return classBackground
        },
    },
}
</script>

<style scoped lang="scss">
.dashboard {
    &__title {
        padding-right: 25px;
        border-right: 1px solid $color-for-nav-link;
        margin-right: 25px;
    }

    &__sub-title {
        @include for-hebrew {
            margin-left: 15px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    &__totally {
        margin-bottom: 15px;
    }

    &__search {
        background: $color-primary-light;
        padding: 15px;
        margin-bottom: 15px;
    }

    &__your-orders {
        background: $color-primary-light;
        padding: 15px;
    }

    .btn-text {
        color: $color-primary-light;
    }
}

.totally {
    display: flex;
    justify-content: space-between;

    &__item {
        width: 31%;
        background: $color-primary-light;
        padding: 15px;
        box-shadow: 0 2px 1px rgb(0 0 0 / 5%);
        border-radius: 3px;
    }

    &__title {
        font-size: 12px;
        font-weight: 500;
        min-height: 36px;
        text-align: center;

        @include for-hebrew {
            text-align: right;
        }

        @include tablet-up {
            min-height: auto;
            text-align: left;
        }
    }

    &__count {
        width: 100%;
        text-align: center;
        font-size: 35px;
        color: #607d8b;
        font-weight: 500;

        @include tablet-up {
            font-size: 63px;
        }
    }
}

.search {
    &__title {
        margin-bottom: 15px;
    }

    &__columns {
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        @include tablet-up {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__item {
        width: 100%;
        margin-bottom: 25px;

        @include tablet-up {
            margin-bottom: 0;
            width: 45%;
        }
    }

    &__input-wrapper {
        margin-bottom: 10px;
    }
}

.your-orders {
    &__action {
        display: flex;
        align-items: center;

        button {
            margin-left: 20px;
            color: $color-primary-light;
        }
    }
}

.table {
    &__row {
        &--orange {
            background: #f6bb42;
        }

        &--green {
            background: #6af789;
        }

        &--yellow {
            background: #ffed01;
        }

        &--light-blue {
            background: #7decff;
        }

        &--red {
            background: #fd5959;
        }

        &--blue {
            background: #57a3ff;
        }

        &--light-brown {
            background: #b09789;
        }

        &--pink {
            background: #e971ff;
        }

        &--dark-orange {
            background: #ff7200;
        }

        &--silver {
            background: #c5c5c5;
        }

        &.is-active {
            background: #f6bb42;
        }
    }
}
</style>
