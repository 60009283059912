<template>
    <v-expansion-panel>
        <v-expansion-panel-header
            color="#f7f7f9"
            data-test-id="wigs--new-wig-properties--top-type--top-type-drop-down-btn"
        >
            <h4 class="order-property__sub-title">
                3. {{ propertiesContent['top-type'] }}
            </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f7f7f9">
            <div
                id="order-property--top-type--tpo-type-select"
                class="order-property__input-wrapper"
            >
                <v-autocomplete
                    v-model="mainValue"
                    outlined
                    :items="mainItems"
                    :label="propertiesContent['top-type']"
                    dense
                    attach="#order-property--top-type--tpo-type-select"
                    :error-messages="properties.errors['topType']"
                    data-test-id="wigs--new-wig-properties--top-type--top-type-select"
                    @change="
                        () => {
                            changeHandle()
                            clearSub()
                        }
                    "
                    @input="properties.errors['topType'] = ''"
                >
                </v-autocomplete>
                <template v-if="mainValue">
                    <v-autocomplete
                        v-model="subValue"
                        outlined
                        :items="subItems[mainValue]"
                        :label="label(mainValue)"
                        dense
                        attach="#order-property--top-type--tpo-type-select"
                        :error-messages="properties.errors['topType']"
                        data-test-id="wigs--new-wig-properties--top-type--top-type-sub-value-select"
                        @change="changeHandle"
                        @input="properties.errors['topType'] = ''"
                    >
                    </v-autocomplete>
                </template>
                <template v-if="mainValue === 'Lace Top'">
                    <v-text-field
                        v-model="properties.fields['topTypeComments']"
                        light
                        outlined
                        dense
                        type="text"
                        name="lace-top-comment"
                        :label="propertiesContent['lace-top-comment']"
                        :error-messages="properties.errors['laceTopComment']"
                        data-test-id="wigs--new-wig-properties--top-type--top-type-comment-text-input"
                        @input="properties.errors['laceTopComment'] = ''"
                    >
                    </v-text-field>
                </template>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import isJson from '@/helpers/isJson'
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/orders'
import languagePropertiesMixin from '@/mixins/language-properties.mixin'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'

export default {
    name: 'OrderFormPropertyTopType',
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['topType']: '',
                    ['topTypeComments']: '',
                },
                errors: {},
            }),
        },
    },
    data() {
        return {
            mainValue: null,
            subValue: null,
        }
    },
    computed: {
        ...mapGetters('orders', { getOrder: getterTypes.getOrder }),
        mainItems: function () {
            return [
                { text: this.propertiesContent['none'], value: null },
                {
                    text: this.propertiesContent['regular-skin'],
                    value: 'Regular Skin',
                },
                {
                    text: this.propertiesContent['lace-top'],
                    value: 'Lace Top',
                },
                {
                    text: this.propertiesContent['weft-top-in-circle'],
                    value: 'Weft Top In Circle',
                },
                {
                    text: this.propertiesContent['skin-heights'],
                    value: 'Skin Heights',
                },
            ]
        },
        subItems: function () {
            return {
                ['Regular Skin']: [
                    { text: this.propertiesContent['none'], value: null },
                    {
                        text: this.propertiesContent['regular'],
                        value: 'Regular',
                    },
                    {
                        text: this.propertiesContent['american'],
                        value: 'American',
                    },
                    { text: this.propertiesContent['fine'], value: 'Fine' },
                ],
                ['Lace Top']: [
                    { text: this.propertiesContent['none'], value: null },
                    { text: this.propertiesContent['curly'], value: 'Curly' },
                    {
                        text: this.propertiesContent['body'],
                        value: 'Body',
                    },
                    {
                        text: this.propertiesContent['straight'],
                        value: 'Straight',
                    },
                    { text: this.propertiesContent['wavy'], value: 'Wavy' },
                ],
                ['Weft Top In Circle']: [
                    { text: this.propertiesContent['none'], value: null },
                    {
                        text: this.propertiesContent['regular'],
                        value: 'Regular',
                    },
                    {
                        text: this.propertiesContent['weft-top-like-fall'],
                        value: 'Weft Top Like Fall',
                    },
                ],
                ['Skin Heights']: [
                    { text: this.propertiesContent['none'], value: null },
                    { text: this.propertiesContent['flat'], value: 'Flat' },
                    {
                        text: this.propertiesContent['with-heights'],
                        value: 'With Heights',
                    },
                ],
            }
        },
        properties: function () {
            return this.dataProperties
        },
        resValue: function () {
            return JSON.stringify([this.mainValue, this.subValue])
        },
        topType: function () {
            return this.properties.fields['topType']
        },
    },
    mounted() {
        setTimeout(() => {
            this.init()
        })
    },
    methods: {
        label: function (label) {
            return this.propertiesContent[regularToSnakeCase(label)]
        },
        init: function () {
            if (!this['topType'] || !isJson(this.topType)) return null
            const value = JSON.parse(this.topType)
            this.mainValue = value[0]
            this.subValue = value[1]
        },
        changeHandle: function () {
            this.properties.fields['topType'] = this.resValue
            this.clearCommentHandle()
        },
        clearCommentHandle: function () {
            if (this.mainValue === 'Lace Top') return
            this.properties.fields['topTypeComments'] = ''
        },
        clearSub: function () {
            this.subValue = null
        },
    },
}
</script>

<style lang="scss" scoped>
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 15px 15px 0;
    }
}
</style>
