<template>
    <main class="main">
        <Header />
        <router-view></router-view>
        <Footer />
    </main>
</template>

<script>
import Header from '@/components/login/header'
import Footer from '@/components/common/footer'

export default {
    name: 'EmptyLayout',
    components: {
        Header,
        Footer,
    },
}
</script>
