<template>
    <div class="logs">
        <div class="logs__section">
            <h3 class="logs__title">
                {{ $t('pages.wigPage.logs.tables.general.header') }}
            </h3>
            <v-data-table
                disable-pagination
                dense
                hide-default-footer
                hide-default-header
                :headers="columnStylingAndTranslate(tables.general.headers)"
                :items="tables.general.items"
                :items-per-page="-1"
                class="elevation-1"
            >
                <!--    eslint-disable-next-line -->
                <template #item.text="{ item }">
                    <b>{{ item.text }}</b>
                </template>
                <!--    eslint-disable-next-line -->
                <template #item.value="{ item, index }">
                    <template v-if="index === 0">
                        <b><DateCell :value="item.value" /></b>
                    </template>
                    <template v-else>
                        <b>{{ item.value }}</b>
                    </template>
                </template>
            </v-data-table>
        </div>
        <div class="logs__section">
            <h3 class="logs__title">
                {{ $t('pages.wigPage.logs.tables.changesClient.header') }}
            </h3>
            <v-data-table
                disable-pagination
                dense
                hide-default-footer
                :headers="
                    columnStylingAndTranslate(tables.clientChangeLogs.headers)
                "
                :items="tables.clientChangeLogs.items"
                :items-per-page="-1"
                class="elevation-1"
            >
                <!--    eslint-disable-next-line -->
                <template #item.date="{ item }">
                    <DateCell :value="item.date" />
                </template>
            </v-data-table>
        </div>
        <div class="logs__section">
            <h3 class="logs__title">
                {{ $t('pages.wigPage.logs.tables.changesManager.header') }}
            </h3>
            <v-data-table
                disable-pagination
                dense
                hide-default-footer
                :headers="
                    columnStylingAndTranslate(tables.managerChangeLogs.headers)
                "
                :items="tables.managerChangeLogs.items"
                :items-per-page="-1"
                class="elevation-1"
            >
                <!--    eslint-disable-next-line -->
                <template #item.date="{ item }">
                    <DateCell :value="item.date" />
                </template>
            </v-data-table>
        </div>
        <div class="logs__section">
            <h3 class="logs__title">
                {{ $t('pages.wigPage.logs.tables.changesState.header') }}
            </h3>
            <v-data-table
                disable-pagination
                dense
                hide-default-footer
                :headers="
                    columnStylingAndTranslate(tables.statusChangeLogs.headers)
                "
                :items="tables.statusChangeLogs.items"
                :items-per-page="-1"
                class="elevation-1"
            >
                <!--    eslint-disable-next-line -->
                <template #item.doneAt="{ item }">
                    <DateCell :value="item.doneAt" />
                </template>
            </v-data-table>
        </div>
        <div class="logs__section">
            <h3 class="logs__title">
                {{ $t('pages.wigPage.logs.tables.sourceHistory.header') }}
            </h3>
            <v-data-table
                disable-pagination
                dense
                hide-default-footer
                data-test-id="wig-page--logs--history-logs"
                :headers="
                    columnStylingAndTranslate(tables.sourceHistoryLogs.headers)
                "
                :items="tables.sourceHistoryLogs.items"
                :items-per-page="-1"
                :item-class="isRowCurrent"
                class="elevation-1 logs__table-row"
            >
                <!--    eslint-disable-next-line -->
                <template #item.doneAt="{ item }">
                    <DateCell :value="item.doneAt" />
                </template>
                <!--    eslint-disable-next-line -->
                <template #item.sourceWig="{ item }">
                    <template v-if="item.sourceWig">
                        <LinkCell :link-cell-props="item.sourceWig" />
                    </template>
                    <template v-else>
                        <div style="width: 100%; text-align: center">
                            <v-icon color="#3bb0da" dark>
                                mdi-folder-plus
                            </v-icon>
                        </div>
                    </template>
                </template>
                <!--    eslint-disable-next-line -->
                <template #item.createdWig="{ item }">
                    <LinkCell :link-cell-props="item.createdWig" />
                </template>
                <!--    eslint-disable-next-line -->
                <template #item.arrow="{ item }">
                    <div class="" style="width: 100%; text-align: center">
                        <v-icon color="green" dark>mdi-arrow-right</v-icon>
                    </div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import languageMixin from '@/mixins/language.mixin'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import { getFullDayFirstFull } from '@/helpers/getDateInFormat'
import { buildLogsFromWigData } from '@/helpers/order-logs.utils'
import DateCell from '@/components/common/cells/date-cell.vue'
import LinkCell from '@/components/common/cells/link-cell.vue'

export default {
    name: 'OrderListOfLogs',
    components: { LinkCell, DateCell },
    mixins: [languageMixin, languageOrderExtraMixin],
    props: {
        wig: {
            type: Object,
            default: () => null,
        },
        logs: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        tables: function () {
            return buildLogsFromWigData(this.wig)
        },
    },
    methods: {
        text: function (text) {
            return this.extraContent[regularToSnakeCase(text)]
        },
        dateFormat: function (date) {
            return date ? getFullDayFirstFull(date) : ''
        },
        columnStylingAndTranslate: function (headers = []) {
            return headers.map((column) => {
                const width = column.width || 80
                let text = column.text
                if (text) {
                    text = this.$t(`pages.wigPage.logs.headers.${text}`)
                }
                return { ...column, text, width, sortable: false }
            })
        },
        isRowCurrent: function (item) {
            return this.wig.id === item.currentWigID
                ? 'logs__table-row logs__table-row--current'
                : 'logs__table-row'
        },
    },
}
</script>

<style lang="scss">
.logs {
    padding: 15px 0;
    font-size: 13px;

    &__title {
        font-size: 16px;
        margin-bottom: 15px;
    }

    &__item {
        border-bottom: 1px solid #d7d7d7;
        margin: 0 15px 5px;
        padding-bottom: 5px;

        @include tablet-up {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    &__user {
        min-width: 75px;
        margin: 0;
        margin-right: 10px;
    }

    &__state {
        margin: 0;
        min-width: 170px;
    }

    &__date {
        margin: 0;
        font-size: 13px;
    }

    &__section {
        margin-bottom: 24px;
    }

    &__table-row {
        &--current {
            background: greenyellow;
        }
    }
}
</style>
