<template>
    <SlideOut ref="slideOut" :is-loading="isLoading" @on-submit="onSubmit">
        <OrdersReworkProperties
            ref="ordersReworkPropertiesUpdate"
            :rework-properties="propertiesToUpdate"
            hide-actions
        />
    </SlideOut>
</template>

<script>
import SlideOut from '@/components/common/slide-out-v2.vue'
import OrdersReworkProperties from '@/components/orders/orders-rework-properties.vue'
import { mapActions, mapGetters } from 'vuex'
import { getterTypesSlideOuts } from '@/store/modules/slide-outs/types'
import { getterTypes as getterTypesOrders } from '@/store/modules/orders'
import {
    actionTypesOrdersReworkProperties,
    getterTypesOrdersReworkProperties,
} from '@/store/modules/orders-rework-properties/types'
import {
    actionTypes as actionTypesRepairProperties,
    getterTypes as getterTypesOrdersRepairProperties,
} from '@/store/modules/order-repairs-stages'

export default {
    name: 'WigPageAdditionalPropertiesUpdateSlideOut',
    components: {
        OrdersReworkProperties,
        SlideOut,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters('slideOuts', {
            getProps: getterTypesSlideOuts.GET_PROPS,
        }),
        ...mapGetters('orders', {
            selectedWig: getterTypesOrders.getOrder,
        }),
        ...mapGetters('ordersReworkProperties', {
            reworkProperties: getterTypesOrdersReworkProperties.getProperties,
        }),
        ...mapGetters('orderRepairsStages', {
            repairProperties:
                getterTypesOrdersRepairProperties.getAdditionalProperties,
        }),
        propertiesToUpdate: function () {
            return this.isReworkProperties
                ? this.reworkProperties
                : this.repairProperties
        },
        isReworkProperties: function () {
            return this.getProps.isReworkProperties
        },
    },
    methods: {
        ...mapActions('ordersReworkProperties', {
            postReworkProperties:
                actionTypesOrdersReworkProperties.createProperties,
            deleteAnyProperties:
                actionTypesOrdersReworkProperties.removeProperties,
        }),
        ...mapActions('orderRepairsStages', {
            postRepairProperties: actionTypesRepairProperties.addNewProperties,
        }),
        onClose() {
            this.$refs.slideOut.onClose()
        },
        onSubmit() {
            try {
                const postProperties = this.isReworkProperties
                    ? this.postReworkProperties
                    : this.postRepairProperties

                let { propertiesForRemoving, propertiesForAdding } =
                    this.$refs.ordersReworkPropertiesUpdate.onSave()
                if (
                    propertiesForRemoving.length === 0 &&
                    propertiesForAdding.length === 0
                ) {
                    return
                }

                propertiesForAdding = propertiesForAdding.map((property) => {
                    return {
                        tittle: property.title,
                        category: property.category,
                        comment: property.comment,
                    }
                })

                if (propertiesForAdding.length !== 0)
                    postProperties({
                        wigID: this.selectedWig.id,
                        newProperties: propertiesForAdding,
                    })

                if (propertiesForRemoving.length !== 0)
                    this.deleteAnyProperties({
                        wigID: this.selectedWig.id,
                        idsForRemoving: propertiesForRemoving,
                    })

                setTimeout(() => {
                    this.onClose()
                }, 100)
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>
<style lang="scss" scoped></style>
