import WigsDetailsPrintPagePropertiesList from '@/features/wig-page/components/wigs-details-print-page-properties-list.vue'
import DateCell from '@/components/common/cells/date-cell.vue'
import { i18n } from '@/plugins/i18n'
import getWigsType from '@/features/wig-page/utils/get-wigs-type.utils'
import {
    propertiesTemplates,
    provideTopTapeProperty,
} from '@/features/wig-page/utils/wigs-details-print-page-proprties-list-templates'

const isThereAnyPropertiesItem = (propertyKey, wig) => {
    return propertiesTemplates[propertyKey]
        .map(({ key }) => key)
        .reduce((acc, key) => {
            if (provideTopTapeProperty(wig.properties)[key]) {
                return true
            }
            return acc
        }, false)
}

const cellsViability = {
    baseColor: (wig) => {
        return isThereAnyPropertiesItem('baseColor', wig)
    },
    length: (wig) => {
        return isThereAnyPropertiesItem('length', wig)
    },
    heirTexture: (wig) => {
        return isThereAnyPropertiesItem('heirTexture', wig)
    },
    wigFullness: (wig) => {
        return isThereAnyPropertiesItem('wigFullness', wig)
    },
    front: (wig) => {
        return isThereAnyPropertiesItem('front', wig)
    },
    capSize: (wig) => {
        return isThereAnyPropertiesItem('capSize', wig)
    },
    topTape: (wig) => {
        return isThereAnyPropertiesItem('topTape', wig)
    },
}

const generateTable = (wig) => {
    console.log(wig)
    const firstColumn = [
        {
            component: DateCell,
            label: 'מוכן לתאריך',
            value: null,
            options: {
                value: wig.complete_data,
            },
            isVisible: true,
        },
        {
            component: WigsDetailsPrintPagePropertiesList,
            label: 'צבע בסיס',
            value: null,
            options: {
                wigProperties: wig.properties,
                template: 'baseColor',
            },
            isVisible: cellsViability.baseColor(wig),
        },
        {
            component: WigsDetailsPrintPagePropertiesList,
            label: 'אורך',
            value: null,
            options: {
                wigProperties: wig.properties,
                template: 'length',
            },
            isVisible: cellsViability.length(wig),
        },
        {
            component: WigsDetailsPrintPagePropertiesList,
            label: 'סוג שיער טרסים',
            value: null,
            options: {
                wigProperties: wig.properties,
                template: 'heirTexture',
            },
            isVisible: cellsViability.heirTexture(wig),
        },
        {
            component: WigsDetailsPrintPagePropertiesList,
            label: 'מלאות הפאה',
            value: null,
            options: {
                wigProperties: wig.properties,
                template: 'wigFullness',
            },
            isVisible: cellsViability.wigFullness(wig),
        },
        {
            component: WigsDetailsPrintPagePropertiesList,
            label: 'גודל רשת',
            value: null,
            options: {
                wigProperties: wig.properties,
                template: 'capSize',
            },
            isVisible: cellsViability.capSize(wig),
        },
        {
            component: WigsDetailsPrintPagePropertiesList,
            label: 'סוג כיפה',
            value: null,
            options: {
                wigProperties: wig.properties,
                template: 'topTape',
            },
            isVisible: cellsViability.topTape(wig),
        },
        {
            component: WigsDetailsPrintPagePropertiesList,
            label: 'פרונט',
            value: null,
            options: {
                wigProperties: wig.properties,
                template: 'front',
            },
            isVisible: cellsViability.front(wig),
        },
    ].filter(({ isVisible }) => isVisible)

    const secondColumn = [
        {
            component: DateCell,
            label: 'תאריך יצירה',
            value: null,
            options: {
                value: wig.created_at,
            },
            isVisible: true,
        },
        {
            component: null,
            label: 'מזהה פאה נוסף',
            value: wig.user_generated_id,
            options: {},
            isVisible: !!wig.user_generated_id,
        },
        {
            component: null,
            label: 'סוג הפאה',
            value: i18n.t(`shared.wigTypes.${getWigsType(wig)}`),
            options: {},
            isVisible: true,
        },
        {
            component: null,
            label: 'פרטים',
            value: wig.details,
            options: {},
            isVisible: !!wig.details,
        },
    ].filter(({ isVisible }) => isVisible)

    let table = []
    const maxCountOfRows = Math.max(firstColumn.length, secondColumn.length)

    for (let i = 0; i < maxCountOfRows; i++) {
        table.push([firstColumn[i] || undefined, secondColumn[i] || undefined])
    }

    return table
}

export default generateTable
