<template>
    <v-dialog v-model="isActive" width="400" @click:outside="closeHandle">
        <div class="client-update">
            <v-btn
                class="client-update__close"
                icon
                color="#55595c"
                @click="closeHandle"
            >
                <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
            <div class="client-update__body">
                <ClientFormSelect
                    :selected-id="getOrder.client_id"
                    @select-handle="onSelect"
                />
            </div>
            <div class="client-update__action">
                <v-btn color="#967adc" dark small @click="updateHandle">
                    {{ textContent['change'] }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import ClientFormSelect from '@/components/forms/client-form-select'

import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/orders'
import languageMixin from '@/mixins/language.mixin'

const { orderClientUpdate } = popUpTypes
export default {
    name: 'OrderModalClientUpdate',
    components: { ClientFormSelect },
    mixins: [modalsMixin, languageMixin],
    data() {
        return {
            selfName: orderClientUpdate,
            clientId: '',
        }
    },
    computed: {
        ...mapGetters('orders', { getOrder: getterTypes.getOrder }),
    },
    methods: {
        ...mapActions('orders', { onUpdate: actionTypes.clientUpdate }),
        onSelect: function ({ id, name }) {
            this.clientId = id
            this.newClient = name
        },
        updateHandle: async function () {
            try {
                const payload = {
                    wigId: this.getOrder.id,
                    clientId: this.clientId,
                    newClient: this.newClient,
                }
                await this.onUpdate(payload)
                this.closeHandle()
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.client-update {
    background: #fff;
    padding: 15px 10px 10px;
    position: relative;

    &__body {
        margin-bottom: 15px;
    }

    &__action {
        width: 100%;
    }

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;

        @include for-hebrew {
            right: auto;
            left: 5px;
        }
    }
}
</style>
