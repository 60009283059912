import api from '@/api/calendar'
import { getRange, getRangeForJewishCalendar } from '@/helpers/rangeFromDate'
import { uniqBy } from 'lodash'
// import { getFullDate } from '@/helpers/getDateInFormat'
import { actionTypes as actionTypesFromOrders } from '@/store/modules/orders'
import calendarRangeHandler from '@/helpers/calendarRangeHandler'
import { getStorage } from '@/helpers/Local'
import { actionTypesJewishCalendar } from '@/store/modules/jewish-calendar/types'
import getSelectedLanguage from '@/store/utils/get-selected-language.utils'

const state = () => ({
    ['orders']: {},
    ['pick_up_new']: {},
    ['repairs']: {},
    ['pick_up_repairs']: {},
    ['us_agent']: {},
    meetings: [],
})

export const getterTypes = {
    getAllForOrders: '[calendar] Get all meeting for orders',
    getAllForPickUpNew: '[calendar] Get all meeting for pick up new',
    getAllForRepairs: '[calendar] Get all meeting for repairs',
    getAllForPickUpRepairs: '[calendar] Get all meeting for pick up repairs',
    getAllForUsAgent: '[calendar] Get all meeting for us agent',
    getMeetingsPerDay: '[calendar] Get all meeting for selected type and day',
}

export const mutationTypes = {
    updateAllMeetings: '[calendar] Change or update all meetings for selected',
    addNewOne: '[calendar] Add the new meeting',
    deleteOne: '[calendar] Delete the selected meeting',
    updateOne: '[calendar] Update the selected meeting',
    clearAllMeetings: '[calendar][mutation] Update the selected meeting',
}

export const actionTypes = {
    getAllForSelected: '[calendar] Load all meeting for selected params',
    addNewOne: '[calendar] Add the new meeting',
    deleteOne: '[calendar] Delete selected meeting',
    updateOne: '[calendar] Update the selected meeting',
}

const getters = {
    [getterTypes.getAllForOrders]: (state) => {
        let meetings = []
        if (Object.keys(state['orders']).length === 0) {
            return []
        }
        Object.keys(state['orders']).forEach((key) => {
            meetings = [...meetings, ...state['orders'][key]]
        })
        return meetings
    },
    [getterTypes.getAllForPickUpNew]: (state) => {
        let meetings = []
        if (Object.keys(state['pick_up_new']).length === 0) return []
        Object.keys(state['pick_up_new']).forEach((key) => {
            meetings = [...meetings, ...state['pick_up_new'][key]]
        })
        return meetings
    },
    [getterTypes.getAllForRepairs]: (state) => {
        let meetings = []
        if (Object.keys(state['repairs']).length === 0) return []
        Object.keys(state['repairs']).forEach((key) => {
            meetings = [...meetings, ...state['repairs'][key]]
        })
        return meetings
    },
    [getterTypes.getAllForPickUpRepairs]: (state) => {
        let meetings = []
        if (Object.keys(state['pick_up_repairs']).length === 0) return []
        Object.keys(state['pick_up_repairs']).forEach((key) => {
            meetings = [...meetings, ...state['pick_up_repairs'][key]]
        })
        return meetings
    },
    [getterTypes.getAllForUsAgent]: (state) => {
        let meetings = []
        if (Object.keys(state['us_agent']).length === 0) return []
        Object.keys(state['us_agent']).forEach((key) => {
            meetings = [...meetings, ...state['us_agent'][key]]
        })
        return meetings
    },
    [getterTypes.getMeetingsPerDay]: (state) => (type, date) => {
        let meetings = []
        Object.values(state[type]).forEach((item) =>
            item.forEach((meeting) => (meetings = [...meetings, meeting]))
        )

        return meetings.filter((meeting) => {
            return meeting.start_at.includes(date)
        })
    },
}

const mutations = {
    [mutationTypes.updateAllMeetings]: (state, { data, type }) => {
        let meetings = {}
        Object.values(data).map((day) => {
            day.forEach((meeting) => {
                meetings[getRange(meeting.start_at)] = [
                    ...(meetings?.[getRange(meeting.start_at)] || []),
                    meeting,
                ]
            })
        })

        Object.keys(meetings).forEach((key) => {
            state[type] = {
                ...state[type],
                [key]: uniqBy(
                    [...(state?.[type]?.[key] || []), ...meetings[key]],
                    'id'
                ),
            }
        })
    },
    [mutationTypes.addNewOne]: (state, meeting) => {
        const typeOfCalendar = meeting.calendar.toLowerCase()
        const range = getRange(meeting.start_at)

        let correctType = ''
        let type = ''
        switch (typeOfCalendar) {
            case 'pick_up_new':
            case 'pick-up new wigs':
                correctType = 'Pick-up New Wigs'
                type = 'pick_up_new'
                break
            case 'pick_up_repairs':
                correctType = 'Pick-up Repairs'
                type = 'pick_up_repairs'
                break
            default:
                type = meeting.calendar.toLowerCase()
                correctType = meeting.calendar
        }

        const newMeeting = {
            [range]: [
                ...(state?.[type]?.[range] || []),
                {
                    ...meeting,
                    calendar: correctType,
                },
            ].filter((i) => i),
        }

        state[type] = {
            ...state[type],
            ...newMeeting,
        }
    },
    [mutationTypes.deleteOne]: (state, { type, id, range }) => {
        state[type][range] = state[type][range].filter(
            (meeting) => meeting.id !== id
        )
    },
    [mutationTypes.clearAllMeetings]: (state) => {
        state['orders'] = {}
        state['pick_up_new'] = {}
        state['repairs'] = {}
        state['pick_up_repairs'] = {}
        state['us_agent'] = {}
        state.meetings = []
    },
}

const actions = {
    [actionTypes.getAllForSelected]: (
        // eslint-disable-next-line no-unused-vars
        { commit, dispatch },
        // eslint-disable-next-line no-unused-vars
        { type, range }
    ) => {
        const isUsa = getStorage('access_role') === 'USA_MANAGER'
        const lang = getSelectedLanguage().lang
        return new Promise((res, rej) => {
            api.getMeetingsV2({
                type,
                range: calendarRangeHandler(range),
                isUsa,
            })
                .then((data) => {
                    getRangeForJewishCalendar(range).forEach(
                        ({ year, month }) => {
                            dispatch(
                                `jewishCalendar/${actionTypesJewishCalendar.loadAll}`,
                                { year, month, lang },
                                {
                                    root: true,
                                }
                            )
                        }
                    )
                    return data
                })
                .then((data) => {
                    commit(mutationTypes.updateAllMeetings, {
                        data,
                        type,
                        range,
                    })
                    res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.addNewOne]: ({ commit, dispatch }, data) => {
        return new Promise((res, rej) => {
            api.addNewOne(data)
                .then((newMeeting) => {
                    const id = newMeeting.id
                    res()
                    commit(mutationTypes.addNewOne, {
                        ...newMeeting,
                        calendar: data.calendar,
                    })
                    dispatch(
                        `orders/${actionTypesFromOrders.addNewMeeting}`,
                        { data, id },
                        {
                            root: true,
                        }
                    )
                })
                .catch((er) => {
                    rej(er?.response?.data?.errors)
                })
        })
    },
    [actionTypes.deleteOne]: (
        { commit, dispatch },
        { range, type, id, wigId }
    ) => {
        return new Promise((res, rej) => {
            api.deleteOne(id)
                .then(() => {
                    res()
                    commit(mutationTypes.deleteOne, { range, type, id })

                    if (wigId) {
                        dispatch(
                            `orders/${actionTypesFromOrders.meetingDelete}`,
                            { id },
                            {
                                root: true,
                            }
                        )
                        dispatch(
                            `orders/${actionTypesFromOrders.updateCompleteDate}`,
                            { id: wigId, completeData: '' },
                            {
                                root: true,
                            }
                        )
                    }
                })
                .catch((er) => {
                    rej(er)
                    console.log(er)
                })
        })
    },
    [actionTypes.updateOne]: ({ commit }, { data, id, oldRange, oldType }) => {
        return new Promise((res, rej) => {
            api.updateOne({ data, id })
                .then((updatedMeeting) => {
                    res()
                    commit(mutationTypes.deleteOne, {
                        type: oldType,
                        id,
                        range: oldRange,
                    })
                    commit(mutationTypes.addNewOne, updatedMeeting)
                })
                .catch((er) => {
                    rej(rej(er.response?.data?.errors))
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
