import { render, staticRenderFns } from "./wig-page-additional-properties-edit-slide-out.vue?vue&type=template&id=c55bf0d8&scoped=true"
import script from "./wig-page-additional-properties-edit-slide-out.vue?vue&type=script&lang=js"
export * from "./wig-page-additional-properties-edit-slide-out.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c55bf0d8",
  null
  
)

export default component.exports