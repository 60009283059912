<template>
    <div class="order-properties">
        <v-expansion-panels v-model="mainPanelIsActive" dark :disabled="false">
            <v-expansion-panel>
                <v-expansion-panel-header
                    color="#967adc"
                    data-test-id="wigs--new-wig-properties--main-drop-down-btn"
                >
                    <h3 class="order-properties__title">
                        {{ propertiesContent['properties'] }}
                    </h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="#fff">
                    <v-expansion-panels v-model="panel" multiple light>
                        <OrderFormPropertyWigType
                            :data-properties="properties"
                        />
                        <OrderFormPropertyCapSize
                            :data-properties="properties"
                        />
                        <OrderFormPropertyTopType
                            :data-properties="properties"
                        />
                        <OrderFormPropertyFront :data-properties="properties" />
                        <OrderFormPropertyBaseColor
                            :data-properties="properties"
                        />
                        <OrderFormPropertyLength
                            :data-properties="properties"
                        />
                        <OrderFormPropertyHairTexture
                            :data-properties="properties"
                        />
                        <OrderFormPropertyWigFullness
                            :data-properties="properties"
                        />
                        <template v-if="disabled">
                            <OrderFormPropertyWeight
                                :data-properties="properties"
                            />
                        </template>
                    </v-expansion-panels>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import OrderFormPropertyWigType from '@/components/forms/order-form-property-wig-type'
import OrderFormPropertyCapSize from '@/components/forms/order-form-property-cap-size'
import OrderFormPropertyTopType from '@/components/forms/order-form-property-top-type'
import OrderFormPropertyFront from '@/components/forms/order-form-property-front'
import OrderFormPropertyBaseColor from '@/components/forms/order-form-property-base-color'
import OrderFormPropertyLength from '@/components/forms/order-form-property-lenght'
import OrderFormPropertyHairTexture from '@/components/forms/order-form-property-hair-texture'
import OrderFormPropertyWigFullness from '@/components/forms/order-form-property-wig-fullness'
import OrderFormPropertyWeight from '@/components/forms/order-form-property-weight'
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/language-pack'
import languagePropertiesMixin from '@/mixins/language-properties.mixin'

export default {
    name: 'OrderFormProperties',
    components: {
        OrderFormPropertyWigType,
        OrderFormPropertyCapSize,
        OrderFormPropertyTopType,
        OrderFormPropertyFront,
        OrderFormPropertyBaseColor,
        OrderFormPropertyLength,
        OrderFormPropertyHairTexture,
        OrderFormPropertyWigFullness,
        OrderFormPropertyWeight,
    },
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {},
                errors: {},
            }),
        },
        // eslint-disable-next-line vue/require-prop-types
        mainPanel: {
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['panel-handler'],
    data() {
        return {
            panelWithError: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            panel: [],
            propertiesState: {},
        }
    },
    computed: {
        properties: function () {
            return this.dataProperties
        },
        mainPanelIsActive: {
            get: function () {
                return this.mainPanel
            },
            set: function (newValue) {
                this.$emit('panel-handler', newValue)
            },
        },
    },
    methods: {
        ...mapActions('languagePack', {
            loadLanguage: actionTypes.getLanguagePack,
        }),
    },
}
</script>

<style lang="scss">
.order-properties {
    &__title {
        width: 100%;
        text-align: center;
        font-weight: 400;
        color: $color-primary-light;
    }

    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 0 10px;
    }
}
</style>
